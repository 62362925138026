/**
 * Import React libraries.
 */
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

/**
 * Import custom utilities.
 */
import { GetSingleEclCourses } from '../../../api/sanity';
/**
 * Import custom types.
 */
import { Course, EclCourseSanity } from '../../../types';
import { EmbeddedCourse } from './EmbeddedCourse';
import { Organization } from '@laerdal/navigation';

interface Props {
  courseId: string;
  userId: string | undefined;
  eclCourses: Course[] | undefined;
  lastLogin: Date | undefined;
  isEclOrganization: boolean;
  organization: Organization | null | undefined;
}

const SingleCourse = ({ courseId, eclCourses, userId, lastLogin, isEclOrganization, organization }: Props) => {
  const [sanityEclCourse, setSanityEclCourse] = useState<EclCourseSanity | undefined>();
  const navigate = useNavigate();

  const dataLoading = useMemo(() => !eclCourses || !sanityEclCourse, [eclCourses, sanityEclCourse]);

  useEffect(() => {
    if (
      !dataLoading &&
      !isEclOrganization &&
      !eclCourses?.find((course) => course.courseCode === sanityEclCourse?.code)
    ) {
      sessionStorage.setItem('courseExpired', sanityEclCourse!._id);
      navigate('/');
    }
  }, [dataLoading, isEclOrganization, eclCourses]);

  /**
   * Does all required pre-requisites and retrieves a specific ECL course.
   */
  useEffect(() => {
    if (courseId) {
      GetSingleEclCourses(courseId)
        .then((eclCourse) => {
          if (!eclCourse) {
            navigate('/');
          } else {
            setSanityEclCourse(eclCourse);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [courseId, dataLoading]);

  return (
    <EmbeddedCourse
      courseCode={sanityEclCourse?.code}
      courseLang={sanityEclCourse?._lang}
      courseUrl={dataLoading ? undefined : sanityEclCourse!.versionList.find((course) => course.isDefault)!.courseUrl}
      userId={userId}
      userLastLogin={lastLogin ? new Date(lastLogin).getTime() : undefined}
    />
  );
};

export default SingleCourse;
