import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import features from './slice/features';
import userReducer from './slice/user';
import orgReducer from './slice/organization';
import settingsReducer from './slice/settings';
import courseReducer from './slice/courses';

const rootReducer = combineReducers({
  user: userReducer,
  organization: orgReducer,
  featuresToggle: features,
  settings: settingsReducer,
  courses: courseReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

// Root state type
export type RootState = ReturnType<typeof rootReducer>;
// Thunk type
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
// Export a useDispatch hook that can be reused to resolve types
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
