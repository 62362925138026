import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n/config';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import './day';

import { ToastProvider } from '@laerdal/life-react-components';
import posthog from 'posthog-js';
import { GlobalNav } from '@laerdal/navigation';

declare const window: any;

if (!('process' in window)) {
  // @ts-ignore
  window.process = {};
}

if (
  process.env.REACT_APP_POSTHOG_PROJECT_ID &&
  !document.cookie.includes('laerdal-signin-on-behalf=') &&
  window.OnetrustActiveGroups?.indexOf('C0002') >= 0
) {
  posthog.init(process.env.REACT_APP_POSTHOG_PROJECT_ID, {
    api_host: 'https://anltcs.laerdal.com',
    ui_host: 'https://eu.posthog.com',
  });
  if (!('posthog' in window)) {
    //@ts-ignore
    window.posthog = posthog;
  }
}

const switcherRoot = document.getElementById('switcherRoot')!;
const switcherRootRoot = createRoot(switcherRoot);
switcherRootRoot.render(
  <Router>
    <ToastProvider>
      <GlobalNav
        ref={(globalNav: GlobalNav) => {
          window.globalNav = globalNav;
        }}
      />
    </ToastProvider>
  </Router>,
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <Router>
      <ToastProvider>
        <App />
      </ToastProvider>
    </Router>
  </Provider>,
);

if (process.env.NODE_ENV === 'development') {
  window.Appcues = {
    identify: (identityId: string, userInfo: any) => console.log('AppCues identify', identityId, userInfo),
    page: () => console.log('AppCues page'),
    reset: () => console.log('AppCues reset'),
    track: (event: string, data: any) => console.log('AppCues track', event, data),
  };

  module?.hot?.accept();
}
