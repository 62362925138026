import axios from 'axios';
import { OrgServiceResponseDto } from '../types';
import AuthApi from './AuthApi';

class InviteApi {
  InviteUsersToOrganization = async (userEmails: string[]): Promise<string[]> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/invite/organization`, userEmails, {
        headers: { Authorization: `Bearer ${await AuthApi.getToken()}` },
      })
      .then((response) => {
        return response.data as string[];
      });
  };
}

export default new InviteApi();
