import { DesktopMenuButtonTypes, MenuNavigationItemTypeItem, SystemIcons } from '@laerdal/life-react-components';
import { NoOrganizationRoute } from '../../constants';

export const unauthenticatedHeaderActions: DesktopMenuButtonTypes[] = [
  {
    type: 'action',
    icon: <SystemIcons.Language />,
    action: (button) => {},
  },
];

export const mainNavigationOptions: MenuNavigationItemTypeItem[] = [
  {
    type: 'item',
    id: 'dashboardLink',
    label: 'Home',
    to: '/',
    exact: true,
  },
];

export const headerActions: DesktopMenuButtonTypes[] = [
  {
    id: 'profileMenuDesktop',
    type: 'profile',
  },
  {
    type: 'switcher',
    className: 'desktop-switcher',
    action: () => {
      // @ts-ignore
      window.globalNav.toggle();
    },
  },
];

export const mobileActions: DesktopMenuButtonTypes[] = [
  {
    id: 'profileMenuMobile',
    type: 'profile',
    placement: 'left',
  },
  {
    type: 'switcher',
    className: 'mobile-switcher',
    action: () => {
      // @ts-ignore
      window.globalNav.toggle();
    },
  },
];

export const supportSection = [
  {
    label: 'Help',
    to: 'https://laerdal.force.com/HelpCenter',
    icon: <SystemIcons.Help />,
    isExternal: true,
  },
  {
    label: 'Support',
    to: 'https://www.laerdal.com/support/',
    icon: <SystemIcons.Support />,
    isExternal: true,
  },
];
