import { ToastContext } from '@laerdal/life-react-components';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserApi } from '../../api';
import { GlobalLoader } from '../../components/common/GlobalLoader';
import { AppName } from '../../constants';
import { AppCuesFlowEventName, NavigationTrackingEventName, TrackingEventType } from '../../helpers/tracking';
import { getToastConfig, ToastType } from '../../helpers/utils';
import { AppDispatch, useAppDispatch } from '../../store';
import { isFeatureActivated, selectFeatures } from '../../store/slice/features';
import { selectAvailableOrganizations, selectOrganization } from '../../store/slice/organization';
import { logOut, selectIsAuthenticated, selectUserId } from '../../store/slice/user';
import { Organization } from '../../types';
import posthog from 'posthog-js';

export const ExternalInvitationPage = () => {
  const [search] = useSearchParams();
  const dispatch = useAppDispatch() as AppDispatch;
  const navigate = useNavigate();
  const { t } = useTranslation('Common');
  const laerdalTracking = window.LaerdalTracking;

  const organization = useSelector(selectOrganization);
  const availableOrganizations = useSelector(selectAvailableOrganizations);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userId = useSelector(selectUserId);
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    //means that the user landed back on this page once he has logged in
    const inviteCustomerNumber = search.get('customerNumber');
    const action = search.get('gig_actions');
    if (action === 'sso.login' && isAuthenticated && availableOrganizations && inviteCustomerNumber && !!organization) {
      if (inviteCustomerNumber != organization?.customerNo) {
        const targetOrg = availableOrganizations?.find((org) => org.customerNo === inviteCustomerNumber);
        if (targetOrg) {
          //set current organization for the current user
          UserApi.SetCurrentUserOrganization(targetOrg?.id)
            .then((val) => {
              //send GA event that the user have been onboarded
              trackUserOnboarded(userId!, targetOrg);
              //trigger appcues event
              sessionStorage.setItem('appcues_org_changed', targetOrg.name);
              //redirect to the dashboard page
              window.location.href = window.location.origin;
            })
            .catch((val) => {
              addToast(t('An error occured. Please restart the process.'), getToastConfig(ToastType.Error));
            });
        } else {
          addToast(t('User is not a member of the target organization.'), getToastConfig(ToastType.Error));
        }
      } else {
        //send GA event that the user have been onboarded
        trackUserOnboarded(userId!, organization);
        //if current org is correctly set, just navigate to the library page
        navigate('/');
      }
    }
  }, [organization, isAuthenticated, availableOrganizations]);

  const trackUserOnboarded = (userId: string, organization: Organization) => {
    let trackingEvent = laerdalTracking?.generateTrackingData(
      TrackingEventType.Navigation,
      NavigationTrackingEventName.USER_ONBOARDED,
      {
        userID: userId,
        customerNumber: organization.customerNo,
        countryId: organization?.address?.country?.codeAlpha2,
      },
    );
    laerdalTracking?.track(trackingEvent);
    posthog.capture(`KH User Onboarded`);
  };

  useEffect(() => {
    const action = search.get('gig_actions');
    const email = search.get('email');
    const lang = search.get('lang');

    if (action !== 'sso.login') {
      dispatch(logOut());
      //@ts-ignore
      window.gigya.sso.login({
        authFlow: 'redirect',
        redirectURL: window.location.href,
        useChildContext: true,
        context: {
          application: AppName,
          allowRegistration: true,
          loginID: email ? getEncodedEmailAddress(search) : '',
          lng: lang,
        },
      });
    }
  }, []);

  const getEncodedEmailAddress = (search: URLSearchParams) => {
    //check if email contains '+' sign (ex: someaccount+test@laerdal.com)
    const emailMatchResult = search.toString().match(/\email=([^&]+)&/);

    if (emailMatchResult) {
      var email = emailMatchResult[1];
      if (/(.*[+])/.test(email)) {
        const encodedSign = encodeURIComponent('+');
        const stringWithEncodedSign = email.replaceAll('+', encodedSign);
        return encodeURIComponent(stringWithEncodedSign);
      }
      return encodeURIComponent(email);
    }
    return '';
  };

  return <GlobalLoader />;
};
