import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { AppDispatch } from '../store';
import {
  setCourseMappingExtraSettings,
  setSanityCourseLabels,
  setSanityCourses,
  setSanitySimulatorOptions,
} from '../store/slice/courses';
import {
  CourseDocumentLabelSanity,
  OptionSanity,
  EclCourseSanity,
  CourseOptionsDocumentSanity,
  CourseVisibilitySetting,
  MappingExtraSettingSanity,
} from '../types';

export const urlFor = (source: string) => {
  const builder = imageUrlBuilder(getClient());
  return builder.image(source);
};

const getClient = () => {
  return sanityClient({
    projectId: process.env.REACT_APP_SANITY_PROJECTID || '',
    dataset: process.env.REACT_APP_SANITY_DATASET || '',
    token: process.env.REACT_APP_SANITY_TOKEN || '', // or leave blank to be anonymous user
    useCdn: true, // `false` if you want to ensure fresh data
    apiVersion: 'v2021-03-25',
  });
};

export const GetEclCourses = async (
  dispatch: AppDispatch,
  setLanguages: ActionCreatorWithPayload<EclCourseSanity[] | undefined, string>,
): Promise<EclCourseSanity[] | void> => {
  const client = getClient();

  const query = `*[_type == "course" && enabled == true] | order(code asc)  | order(title asc) {
      _id,
      _lang,
      authors,
      code,
      description,
      durationFrom,
      durationTo,
      enabled,
      image,
      title,
      versionList, 
      label,
      simulator,
      releaseDate
    }`;

  return client
    .fetch(query, {})
    .then((data: EclCourseSanity[]) => {
      dispatch(setSanityCourses(data));
    })
    .catch(() => {
      dispatch(setSanityCourses([]));
    });
};

export const GetCoursesByIds = async (courseIds: string[]): Promise<EclCourseSanity[]> => {
  const client = getClient();
  const query = `*[_type == "course" && enabled == true && _id in $courseIds]{
    _id,
    _lang,
    authors,
    code,
    courseUrl,
    description,
    durationFrom,
    durationTo,
    enabled,
    image,
    title,
    version,
    label
  }`;

  return client
    .fetch(query, { courseIds: courseIds })
    .then((data: any) => data)
    .catch(() => {
      return null;
    });
};

export const GetCourseLabels = async (dispatch: AppDispatch) => {
  const client = getClient();
  //retrieve also en labels to fallback if none found for current locale
  const query = `*[_type == "courseLabels"]`;
  client
    .fetch(query)
    .then((data: CourseDocumentLabelSanity[]) => {
      const localeLabelsMapping: { [locale: string]: OptionSanity[] } = {};
      data.forEach((labelDocument: CourseDocumentLabelSanity) => {
        localeLabelsMapping[labelDocument._lang] = labelDocument.items;
      });
      dispatch(setSanityCourseLabels(localeLabelsMapping));
    })
    .catch(() => {
      return null;
    });
};

export const GetCourseMappingDetailsByVisibility = async (dispatch: AppDispatch) => {
  const client = getClient();
  const query = `*[_type == 'eclMappings' && !(_id in path("drafts.**"))][0]{items[_type == 'eclMapping' && (defined(freeForLimitedTime) || defined(releaseDate))]{courseCode, freeForLimitedTime,releaseDate}}.items`;
  client
    .fetch(query)
    .then((data: MappingExtraSettingSanity[]) => {
      dispatch(setCourseMappingExtraSettings(data));
    })
    .catch(() => {
      return null;
    });
};

export const getSimulators = async (dispatch: AppDispatch) => {
  const client = getClient();
  const query = `*[_type == "simulators"]`;
  client
    .fetch(query)
    .then((data: CourseOptionsDocumentSanity[]) => {
      dispatch(setSanitySimulatorOptions(data[0].items));
    })
    .catch(() => {
      return null;
    });
};

export const GetCoursesByCode = async (courseCode: string): Promise<EclCourseSanity[]> => {
  const client = getClient();

  const query = `*[_type == "course" && enabled == true && code == $courseCode && !(_id in path("drafts.**"))]{
    _id,
    _lang,
    authors,
    code,
    description,
    duration,
    enabled,
    image,
    title,
    versionList
  }`;

  return client
    .fetch(query, { courseCode: courseCode })
    .then((data: any) => data)
    .catch(() => {
      return null;
    });
};

export const GetSingleEclCourses = async (courseId: string): Promise<EclCourseSanity> => {
  const client = getClient();

  const query = `*[_type == "course" && enabled == true && _id == $courseId]{
    _id,
    _lang,
    authors,
    code,
    description,
    duration,
    enabled,
    image,
    title,
    versionList
  }`;

  return client
    .fetch(query, { courseId: courseId })
    .then((data: any) => data[0])
    .catch(() => {
      return null;
    });
};
