import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import UserApi from '../api/UserApi';
import i18n from '../i18n/config';
import store, { AppDispatch } from '../store';
import { selectOrganization, setAvailableOrganizations, setOrganization } from '../store/slice/organization';
import { selectMarkets } from '../store/slice/settings';
import { logOut, selectIsAuthenticated, selectLocale, setMarket, setUserInfo, validate } from '../store/slice/user';
import { Industry, User } from '../types';
import posthog from 'posthog-js';

export const useAuthentication = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const markets = useSelector(selectMarkets);
  const organization = useSelector(selectOrganization);
  const locale = useSelector(selectLocale);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = store.dispatch as AppDispatch;

  /**
   * Get account info from gigya
   * */
  useEffect(() => {
    dispatch(
      validate((email, locale) => {
        i18n.changeLanguage(locale);
        const expectedEmail = searchParams.get('_email');
        if (!!expectedEmail) {
          if (email === expectedEmail) {
            setSearchParams({}, { replace: true });
          } else {
            dispatch(logOut());
          }
        }
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  /**
   * Fetch user information after authentication
   * */
  useEffect(() => {
    if (isAuthenticated) {
      UserApi.GetCurrentUserInfo().then((userDetails: User) => {
        dispatch(setOrganization(userDetails?.currentOrganization));
        dispatch(setAvailableOrganizations(userDetails?.organizations || undefined));

        if (userDetails) {
          posthog.identify(userDetails.id, {
            org_role: userDetails.currentOrganization?.members
              ?.find((member) => member.user.id === userDetails.id)
              ?.permissions.findIndex((permission) => permission.permissionId === 'account.owner')
              ? 'admin'
              : 'user',
            language: locale ? locale : 'en',
            user_profile: userDetails.companyRoles?.map((role) => role.name).join('/'),
          });

          if (userDetails.currentOrganization) {
            // Group user for organization
            posthog.group(
              'organisation',
              userDetails.currentOrganization.customerNo?.length > 0
                ? userDetails.currentOrganization.customerNo
                : userDetails.currentOrganization.id,
              {
                industry: !!userDetails.currentOrganization.industry
                  ? [userDetails.currentOrganization.industry]
                  : undefined,
                country: userDetails.currentOrganization.address.country.name,
                country_iso_code: userDetails.currentOrganization.address.country.codeAlpha2,
                customer_number: userDetails.currentOrganization.customerNo,
                org_id: userDetails.currentOrganization.id,
              },
            );
          }
          // Capture SignIn event
          posthog.capture('User SignedIn');
        }
        dispatch(setUserInfo(userDetails));
      });
    }
  }, [isAuthenticated]);

  /**
   * Set the current user market
   * */
  useEffect(() => {
    if (organization && markets?.length) {
      if (organization.organizationCode?.length) {
        var market = markets.find(
          (m) => m.organizationCode?.toLowerCase() == organization.organizationCode?.toLowerCase(),
        );
        dispatch(setMarket(market?.code.toLowerCase()));
      } else {
        var webshopService = organization?.services.find((x) => x.name.toLowerCase() == 'webshop');
        dispatch(setMarket(webshopService?.commerceMarket?.code.toLowerCase()));
      }
    }
  }, [markets, organization]);
};
