import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { Organization } from '../../../types';
import { OrganizationSliceState } from './types';

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organization: undefined,
  } as OrganizationSliceState,
  reducers: {
    resetState: (state, _action: AnyAction) => {
      state.organization = null;
    },
    setAvailableOrganizations: (state, action: PayloadAction<Organization[] | undefined | null>) => {
      state.availableOrganizations = action.payload;
    },
    setOrganization: (state, action: PayloadAction<Organization | undefined | null>) => {
      state.organization = action.payload;
    },
  },
});

export const { resetState, setOrganization, setAvailableOrganizations } = organizationSlice.actions;

export const selectOrganization = (state: RootState) => state?.organization.organization;

export const selectAvailableOrganizations = (state: RootState) => state?.organization.availableOrganizations;

export default organizationSlice.reducer;
