import { ModalDialog, SystemIcons } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { urlFor } from '../../../api/sanity';
import { NavigationTrackingEventName, trackCourseData } from '../../../helpers/tracking';
import { formatDuration } from '../../../helpers/utils';
import { CourseExtraVisibilitySetting, EclCourseSanity } from '../../../types';
import { ModalDescription } from '../styles';
import course_fallback from '../../../assets/course-fallback-image.png';
import { useDynamicSize } from '../../../hooks/useDynamicSize';

interface Props {
  showModal: boolean;
  courseNotInOrgLanguage?: boolean;
  closeModal: () => void;
  selectedCourse: EclCourseSanity | undefined;
  customerNumber: string | undefined;
  userId: string | undefined;
  customerCountryIso: string | undefined;
}

export const CourseDetailsModal = ({
  showModal,
  closeModal,
  selectedCourse,
  userId,
  customerNumber,
  courseNotInOrgLanguage,
  customerCountryIso,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('Ecl');
  const size = useDynamicSize();

  return (
    <ModalDialog
      isModalOpen={showModal}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      contentOverflow="auto"
      topImage={
        selectedCourse
          ? selectedCourse.image?.asset?._ref
            ? urlFor(selectedCourse.image?.asset?._ref).url()
            : course_fallback
          : null
      }
      title={selectedCourse?.title}
      submitAction={() => {}}
      size={size}
      note={
        courseNotInOrgLanguage
          ? t('This content is not yet available in your selected language. It is displayed in English')
          : undefined
      }
      leftFooterAction={
        selectedCourse?.extraVisibilitySettings === CourseExtraVisibilitySetting.FreeForLimitedTime
          ? {
              actionType: 'note',
              icon: <SystemIcons.Information size="24px" />,
              text: t('Free for limited time'),
            }
          : undefined
      }
      buttons={[
        {
          id: 'cancel',
          variant: 'tertiary',
          text: t('Close'),
          action: () => {
            trackCourseData(NavigationTrackingEventName.CLICK_ON_CLOSE_COURSE_PREVIEW, selectedCourse);
            closeModal();
          },
        },
        {
          id: 'openCourse',
          action: () => {
            trackCourseData(
              NavigationTrackingEventName.CLICK_ON_OPEN_COURSE,
              selectedCourse,
              userId,
              customerNumber,
              customerCountryIso,
            );
            navigate(`/courses/${selectedCourse!._id}`);
          },
          text: t('Open course'),
          variant: 'primary',
        },
      ]}>
      <>
        <ModalDescription>{selectedCourse?.description}</ModalDescription>
        {formatDuration(selectedCourse) && (
          <ModalDescription>
            {t('Duration')}: {formatDuration(selectedCourse)}
          </ModalDescription>
        )}
      </>
    </ModalDialog>
  );
};
