import { BREAKPOINTS, Button, Size, SystemIcons } from '@laerdal/life-react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PermissionsType, usePermissions } from '../../../hooks/usePermissions';
import { InviteUsersModal } from '../modals/InviteUsersModal';
import { StyledBackButton, Subtitle, Title, TopWrapper } from '../styles';
import styled from 'styled-components';
import { useDynamicSize } from '../../../hooks/useDynamicSize';
import { useMediaMatch } from 'rooks';

interface Props {
  title: string;
  subtitle?: string;
  showShareBtn?: boolean;
  showBackButton?: boolean;
  backButtonTitle?: string;
}

const TitleDiv = styled.div`
  max-width: 800px;
`;

export const PageHeader = ({ title, subtitle, showBackButton, showShareBtn, backButtonTitle }: Props) => {
  const { t } = useTranslation('Ecl');
  const [showInviteModal, setShowInviteModal] = useState(false);
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const size = useDynamicSize();
  const navigate = useNavigate();
  const { hasPermissions } = usePermissions();

  const onShareLibraryBtnClick = () => {
    setShowInviteModal(true);
  };

  return (
    <>
      <TopWrapper mobileView={!isMediumScreen}>
        <TitleDiv>
          {showBackButton && (
            <StyledBackButton
              size={isMediumScreen ? Size.Small : Size.XSmall}
              onClick={() => {
                navigate('/');
              }}>
              {backButtonTitle}
            </StyledBackButton>
          )}
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleDiv>
        {showShareBtn && hasPermissions(PermissionsType.ManageAccess) && (
          <div>
            <Button
              size={size}
              variant="tertiary"
              icon={<SystemIcons.Share size="24px" />}
              className={'shareLibraryBtn'}
              onClick={onShareLibraryBtnClick}>
              {t('Share this library')}
            </Button>
          </div>
        )}
      </TopWrapper>
      <InviteUsersModal showModal={showInviteModal} closeModal={() => setShowInviteModal(false)} />
    </>
  );
};
