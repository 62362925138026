import { GlobalNavigationBar, MenuNavigationItemTypeItem, SystemIcons } from '@laerdal/life-react-components';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppDispatch, useAppDispatch } from '../../store';
import { isFeatureActivated, selectFeatures } from '../../store/slice/features';
import {
  logOut,
  selectEmail,
  selectFirstName,
  selectIsAuthenticated,
  selectIsAuthorized,
  selectLastName,
  selectMarket,
} from '../../store/slice/user';
import { headerActions, mainNavigationOptions, mobileActions } from './GlobalNavigationConfig';
import { CustomLinkProps, ProfileComponent } from '@laerdal/navigation';
import i18n from '../../i18n/config';
import { selectOrganization } from '../../store/slice/organization';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsCurrentRoutePath } from '../common/CurrentPathHook';
import { AppName, LocationsToHideHeaderElements, SingleCourseRoute } from '../../constants';
import { ContentLanguageModal } from '../modals/ContentLanguge/ContentLanguageModal';

const Header: React.FunctionComponent = (): React.ReactElement => {
  const features = useSelector(selectFeatures);
  const dispatch = useAppDispatch() as AppDispatch;
  const organization = useSelector(selectOrganization);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAuthorized = useSelector(selectIsAuthorized);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const email = useSelector(selectEmail);
  const market = useSelector(selectMarket);
  const location = useLocation();
  const { t } = useTranslation(['Common']);
  const [actions, setActions] = useState(headerActions);
  const [navigationItems, setNavigationItems] = useState<MenuNavigationItemTypeItem[]>([]);
  const [customProfileMenuItems, setCustomProfileMenuItems] = useState<CustomLinkProps[]>([]);
  const [isContentLanguageModalOpen, setContentLanguageModalOpen] = useState<boolean>(false);
  const includeHomeButton = useIsCurrentRoutePath([SingleCourseRoute]);
  const navigate = useNavigate();

  useEffect(() => {
    if (LocationsToHideHeaderElements.findIndex((element) => location.pathname.includes(element)) > -1) {
      setActions([headerActions[0]]);
      setCustomProfileMenuItems([]);
    } else {
      if (includeHomeButton) {
        setNavigationItems(
          mainNavigationOptions.map((o) => ({
            ...o,
            type: 'item',
            label: i18n.t(o.label, { ns: 'Common' }),
          })),
        );
      } else {
        setNavigationItems([]);
      }
      setActions(headerActions);

      if (!!organization) {
        setCustomProfileMenuItems([
          {
            label: i18n.t('Preferences', { ns: 'Common' }),
            onClick: () => {
              setContentLanguageModalOpen(true);
            },
            icon: <SystemIcons.GearSettings />,
          } as CustomLinkProps,
        ]);
      }
    }
  }, [organization, includeHomeButton]);

  const profile = () => (
    <ProfileComponent
      user={{
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
        market: market,
      }}
      //@ts-ignore
      env={process.env.REACT_APP_SWITCHER_ENV!}
      locale={i18n.language}
      onSignOut={() => {
        {
          dispatch(logOut());
          setTimeout(() => {
            navigate('/login');
          }, 1000);
        }
      }}
      customContent={{
        title: AppName,
        items: customProfileMenuItems,
      }}
      organizationName={organization?.name}
    />
  );

  return (
    <>
      <GlobalNavigationBar
        name={AppName}
        desktop={{
          buttons: isAuthenticated ? actions : [],
          items: navigationItems ?? [],
          action:
            isAuthenticated && organization && process.env?.REACT_APP_CONNECT_URL
              ? {
                  action: () => (window.location.href = process.env?.REACT_APP_CONNECT_URL!),
                  label: t('Dashboard', { ns: 'Common' }),
                }
              : undefined,
        }}
        mobile={{ buttons: isAuthenticated ? mobileActions : [], items: navigationItems }}
        isAuthenticated={
          LocationsToHideHeaderElements.findIndex((element) => location.pathname.includes(element)) > -1
            ? false
            : isAuthenticated && isAuthorized
        }
        logo={{ showBetaTag: false }}
        profileComponent={isAuthenticated ? profile() : undefined}
        useMaxWidth={true}
      />
      <ContentLanguageModal
        isModalOpen={isContentLanguageModalOpen}
        closeModal={() => setContentLanguageModalOpen(false)}
      />
    </>
  );
};

export default Header;
