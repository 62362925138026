import { MessageContainer } from './styles';
import icon from '../../assets/no-org.svg';
import { Button } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
export const NoCourses = () => {
  const { t } = useTranslation('Ecl');
  const onClick = () => {
    window.open(`https://laerdal.force.com/HelpCenter/s/contactsupport`, '_blank', 'noopener,noreferrer');
  };
  return (
    <MessageContainer>
      <img src={icon} alt="No courses" />
      <h1>{t('No courses to display')}</h1>
      <p>
        {t('There are currently no courses available. Check back again later.')} {t('Need help? Please contact our support.')}
      </p>
      <Button onClick={onClick} variant="secondary">
        {t('Contact support')}
      </Button>
    </MessageContainer>
  );
};
