import { Organization } from '@laerdal/navigation';
import { AnyAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import EclApi from '../../../api/EclApi';
import {
  GetCourseLabels,
  GetCourseMappingDetailsByVisibility,
  GetEclCourses,
  getSimulators,
} from '../../../api/sanity';
import { compareProp, getMinDateValue, groupBy } from '../../../helpers/utils';
import { FilterByAccessedDateOptions, SortOptions } from '../../../pages/Ecl/components/FilterAndSortOptions';
import {
  OptionSanity,
  CourseTracking,
  EclCourseSanity,
  Course,
  CourseDetails,
  CourseVisibilitySetting,
  MappingExtraSettingSanity,
  CourseExtraVisibilitySetting,
} from '../../../types';
import { AppDispatch, RootState } from '../../index';
import { CourseFilterCriteria, CoursessSliceState } from './types';
import dayjs from 'dayjs';

export const DISPLAY_ALL_FILTER_VALUE = 'all';

export const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    courseCodes: undefined,
    sanityCourses: undefined,
    courseLabels: {},
    courseTrackingData: undefined,
    localeComputedCourses: [],
    simulators: [],
    sortOrder: undefined,
    // we should replace this later -
    loaded: false,
    filterCriteria: {
      label: DISPLAY_ALL_FILTER_VALUE,
      simulator: DISPLAY_ALL_FILTER_VALUE,
      accessedDate: undefined,
    },
    extraCourseSettings: undefined,
  } as CoursessSliceState,
  reducers: {
    resetState: (state, _action: AnyAction) => {
      state.courseCodes = undefined;
      state.sanityCourses = undefined;
      state.courseTrackingData = undefined;
      state.loaded = false;
      state.sortOrder = undefined;
      state.localeComputedCourses = [];
      state.courseLabels = {};
      state.filterCriteria = {
        label: DISPLAY_ALL_FILTER_VALUE,
        simulator: DISPLAY_ALL_FILTER_VALUE,
        accessedDate: undefined,
      };
      state.simulators = [];
      state.extraCourseSettings = [];
    },
    resetTrackingData: (state, _action: AnyAction) => {
      state.courseTrackingData = undefined;
    },
    setCourseTrackingLogs: (state, action: PayloadAction<CourseTracking[] | undefined>) => {
      state.courseTrackingData = action.payload ?? [];
    },
    setLocaleComputedCourses: (state, action: PayloadAction<CourseDetails[] | undefined>) => {
      //move component logic here
      state.localeComputedCourses = action.payload;
    },
    setSortCriteria: (state, action: PayloadAction<SortOptions>) => {
      //move component logic here
      state.sortOrder = action.payload;
    },
    setCourseCodes: (state, action: PayloadAction<Course[] | undefined>) => {
      state.courseCodes = action.payload;
    },
    setSanityCourses: (state, action: PayloadAction<EclCourseSanity[] | undefined>) => {
      state.sanityCourses = action.payload;
    },
    setSanityCourseLabels: (state, action: PayloadAction<{ [locale: string]: OptionSanity[] } | null>) => {
      state.courseLabels = action?.payload ?? {};
    },
    setSanitySimulatorOptions: (state, action: PayloadAction<OptionSanity[]>) => {
      state.simulators = action?.payload ?? {};
    },
    setFilterCriteria: (state, action: PayloadAction<CourseFilterCriteria>) => {
      state.filterCriteria = action.payload;
    },
    setCourseMappingExtraSettings: (state, action: PayloadAction<MappingExtraSettingSanity[]>) => {
      state.extraCourseSettings = action.payload;
    },
  },
});

export const {
  resetState,
  resetTrackingData,
  setCourseCodes,
  setSanityCourses,
  setSanityCourseLabels,
  setSanitySimulatorOptions,
  setCourseTrackingLogs,
  setLocaleComputedCourses,
  setSortCriteria,
  setFilterCriteria,
  setCourseMappingExtraSettings,
} = coursesSlice.actions;

export const fetchCourseCodes = () => (dispatch: AppDispatch) => {
  EclApi.GetCourseCodes(dispatch, setCourseCodes, true).catch((error: any) => console.error(error));
  // Let's disable this for now.
  // setTimeout(() => {
  //   // fetch from apis again in 15 seconds
  //   EclApi.GetEclProductCodes(dispatch, setCourseCodes, false).catch((error: any) => console.error(error));
  // }, 1000 * 15);
};

export const fetchSanityCourses = () => (dispatch: AppDispatch) => {
  GetEclCourses(dispatch, setSanityCourses).catch((error: any) => console.error(error));
};

export const fetchSimulators = () => (dispatch: AppDispatch) => {
  getSimulators(dispatch).catch((error: any) => console.error(error));
};

export const fetchSanityCourseLabels = () => (dispatch: AppDispatch) => {
  GetCourseLabels(dispatch).catch((error: any) => console.error(error));
};

//get extra visibility settings information by visibility type
export const fetchCourseMappingExtraSettings = () => (dispatch: AppDispatch) => {
  GetCourseMappingDetailsByVisibility(dispatch).catch((err: any) => console.log(err));
};

export const fetchCourseTracking = () => (dispatch: AppDispatch) => {
  EclApi.GetCourseTracking(dispatch, setCourseTrackingLogs).catch((err: any) => console.log(err));
};

export const selectCourseCodes = (state: RootState) => state?.courses?.courseCodes;
export const selectSanityCourses = (state: RootState) => state?.courses?.sanityCourses;
export const selectSanityCourseLabels = (state: RootState) => state?.courses?.courseLabels;
export const selectCoursesLoaded = (state: RootState) => state?.courses?.loaded;
export const selectSortCriteria = (state: RootState) => state?.courses?.sortOrder;
export const selectFilterCriteria = (state: RootState) => state?.courses?.filterCriteria;
export const selectSimulatorOptions = (state: RootState) => state?.courses?.simulators;
export const selectAllCourses = (state: RootState) => state?.courses?.localeComputedCourses;
export const selectCourseTrackingLogs = (state: RootState) => state?.courses?.courseTrackingData;
export const selectCoursesExtraVisibilitySettings = (state: RootState) => state?.courses?.extraCourseSettings;

export const selectInitialized = createSelector(
  (state: RootState) => state.courses.courseCodes,
  (state: RootState) => state.courses.sanityCourses,
  (state: RootState) => state.courses.courseLabels,
  (state: RootState) => state.courses.simulators,
  (state: RootState) => state.courses.courseTrackingData,
  (state: RootState) => state.courses.extraCourseSettings,
  (courses, sanityCourses, courseLabels, simulators, courseTracking, extraCourseVisibilitySettings) => {
    return (
      !!courses &&
      !!sanityCourses &&
      !!courseLabels &&
      !!simulators &&
      !!courseTracking &&
      !!extraCourseVisibilitySettings
    );
  },
);

export const selectFilteredCourses = createSelector(
  [
    (state: RootState) => state.courses.courseTrackingData,
    (state: RootState) => state.courses.localeComputedCourses,
    (state: RootState) => state.courses.sortOrder,
    (state: RootState) => state.courses.filterCriteria,
    (state: RootState) => state.organization.organization,
    (state: RootState) => state.courses.extraCourseSettings,
  ],
  (
    courseTrackingData,
    localeComputedCourses,
    sortCriteria,
    filterCriteria,
    currentOrg,
    extraCourseVisibilitySettings,
  ) => {
    if (localeComputedCourses?.length && !!courseTrackingData) {
      if (filterCriteria.simulator != DISPLAY_ALL_FILTER_VALUE) {
        localeComputedCourses = localeComputedCourses.filter((course) => course.simulator === filterCriteria.simulator);
      }
      if (filterCriteria.label != DISPLAY_ALL_FILTER_VALUE) {
        localeComputedCourses = localeComputedCourses.filter((course) => course.label === filterCriteria.label);
      }
      let result: CourseDetails[] = localeComputedCourses;
      const isEclOrganization = currentOrg?.id === process.env.REACT_APP_ECL_ORGANIZATION_ID;

      if (!isEclOrganization) {
        //we will have a 1-1 mapping (code-course)
        const codeCourseGroupping: Map<string, CourseDetails[]> = groupBy(localeComputedCourses, (x) => x.code);
        Array.from(codeCourseGroupping.keys()).map((code) => {
          const extraCourseSettings = extraCourseVisibilitySettings?.find((c) => c.courseCode === code);
          const lastAccessDate = courseTrackingData?.find((c) => c.courseCode === code)?.lastAccessed;
          const mapValues = codeCourseGroupping.get(code)![0];
          const thresholdPeriodRecentlyAdded = !!process.env.REACT_APP_RECENTLY_ADDED_THRESHOLD_DAYS
            ? parseInt(process.env.REACT_APP_RECENTLY_ADDED_THRESHOLD_DAYS)
            : 30;
          const maxDisplayedItems = !!process.env.REACT_APP_RECENTLY_ADDED_MAX_ITEMS
            ? parseInt(process.env.REACT_APP_RECENTLY_ADDED_MAX_ITEMS)
            : 8;
          const recentlyAddedItems = Array.from(codeCourseGroupping.values())
            .flatMap((x) => x)
            .filter((course) => course.recentlyAdded);

          const recentlyAdded = !!extraCourseSettings?.releaseDate
            ? recentlyAddedItems.length < maxDisplayedItems &&
              dayjs() <= dayjs(extraCourseSettings?.releaseDate).add(thresholdPeriodRecentlyAdded, 'day')
            : false;
          codeCourseGroupping.set(code, [
            {
              ...mapValues,
              recentlyAdded: recentlyAdded,
              order: lastAccessDate ? lastAccessDate : getMinDateValue(),
              extraVisibilitySettings: extraCourseSettings?.freeForLimitedTime
                ? CourseExtraVisibilitySetting.FreeForLimitedTime
                : undefined,
            },
          ]);
        });

        result = Array.from(codeCourseGroupping.values()).flatMap((x) => x);
      } else {
        result = result.map((r) => {
          const freeForLimitedTime = extraCourseVisibilitySettings?.find(
            (c) => c.courseCode === r.code,
          )?.freeForLimitedTime;
          return {
            ...r,
            order: getMinDateValue(),
            extraVisibilitySettings: freeForLimitedTime ? CourseExtraVisibilitySetting.FreeForLimitedTime : undefined,
          };
        });
      }

      if (!!filterCriteria.accessedDate && filterCriteria.accessedDate != FilterByAccessedDateOptions.All) {
        // if (filterCriteria.accessedDate === FilterByAccessedDateOptions.NewToYou) {
        //   result = result.filter((r) => r.order === getMinDateValue());
        // } else
        if (filterCriteria.accessedDate === FilterByAccessedDateOptions.Opened) {
          result = result.filter((r) => r.order !== getMinDateValue());
        }
      }

      if (sortCriteria === SortOptions.Relevance) {
        return result.sort(compareProp('order', true));
      } else {
        const copyCourses = [
          ...result.map((s) => {
            return { ...s, title: s.title.trim() };
          }),
        ].sort(compareProp('title', sortCriteria === SortOptions.TitleDescending));
        return copyCourses;
      }
    }
    return undefined;
  },
);

export default coursesSlice.reducer;
