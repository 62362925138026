import { GlobalNav } from '@laerdal/navigation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthorized } from '../store/slice/user';

declare global {
  interface Window {
    globalNav: GlobalNav;
  }
}

export const useSwitcher = () => {
  const authorized = useSelector(selectIsAuthorized);

  useEffect(() => {
    if (authorized) {
      window.globalNav?.initialize({
        env: process.env.REACT_APP_SWITCHER_ENV,
      });
    }
  }, [authorized]);
};
