import { useTranslation } from 'react-i18next';
import { urlFor } from '../../../api/sanity';
import { formatDuration, getLanguageByCode } from '../../../helpers/utils';
import { CourseDetails, CourseExtraVisibilitySetting, Language } from '../../../types';
import { useCourseLabels } from '../hooks/CourseLabelsHook';
import { StyledHorizontalCard, StyledVerticalCard } from '../styles';
import course_fallback from '../../../assets/course-fallback-image.png';
import {
  CardBottomSectionProps,
  CardTopSectionProps,
  COLORS,
  HorizontalCard,
  HorizontalCardTag,
  SystemIcons,
  TagVariants,
} from '@laerdal/life-react-components';
import { NavigationTrackingEventName, trackCourseData } from '../../../helpers/tracking';
import dayjs from 'dayjs';
import { selectUserContentLanguage } from '../../../store/slice/user';
import { useSelector } from 'react-redux';
import { selectOrganization } from '../../../store/slice/organization';
import { useMemo } from 'react';

interface Props {
  showExpireTag?: boolean;
  cardType?: 'horizontal' | 'vertical';
  course: CourseDetails;
  languages: Language[] | undefined;
  customClassName: string | undefined;
  isEclOrg: boolean;
  onOpenModal: (course: CourseDetails) => void;
}

export const CourseCardItem = ({
  isEclOrg,
  cardType = 'vertical',
  showExpireTag = false,
  course,
  languages,
  customClassName,
  onOpenModal,
}: Props) => {
  const { getCategoryLabelProps } = useCourseLabels();
  const categoryProps = getCategoryLabelProps(course);
  const { t } = useTranslation('Ecl');
  const organization = useSelector(selectOrganization);
  const contentLanguage = useSelector(selectUserContentLanguage);

  const getHorizontalCourseTags = (course: CourseDetails, languages: Language[] | undefined) => {
    const tags: { label: string; variant?: TagVariants }[] = [];
    // if (course.order !== getMinDateValue()) {
    //   tags.push({ label: getLastAccessedLabel(course.order), variant: 'accent1' });
    // }

    if (!contentLanguage?.toLowerCase()?.includes(course._lang.toLowerCase()) || isEclOrg) {
      tags.push({ label: getLanguageByCode(languages, course._lang) ?? '' });
    }

    if (course.extraVisibilitySettings === CourseExtraVisibilitySetting.FreeForLimitedTime) {
      tags.push({ label: t('Free for limited time'), variant: 'accent2' });
    }

    return tags;
  };

  const getHeaderTagLabel = (course: CourseDetails): Partial<CardTopSectionProps> | undefined => {
    if (showExpireTag) {
      var dateDiff = dayjs(course.expireAt).diff(dayjs());
      return {
        tagLabel: t('Available for {{nrOfDays}} days', { nrOfDays: Math.floor(dayjs.duration(dateDiff).asDays()) }),
        tagVariant: 'neutral',
      };
    } else if (course.extraVisibilitySettings === CourseExtraVisibilitySetting.FreeForLimitedTime) {
      return { tagLabel: t('Free for limited time'), tagVariant: 'accent2' };
    }
    return undefined;
  };

  const openModal = (course: CourseDetails) => {
    trackCourseData(NavigationTrackingEventName.CLICK_ON_COURSE_PREVIEW, course);
    onOpenModal(course);
    return () => {};
  };

  const getVerticalCardRibbonProps = (date: number) => {
    if (course.recentlyAdded) {
      return {
        highlightRibbonBgColor: COLORS.accent2_100,
        highlightRibbonContentColor: COLORS.accent2_600,
        highlightRibbonIcon: <SystemIcons.StarOutlined size="24px" />,
        highlightRibbonText: t('New to KnowledgeHub'),
      };
    }
    return {};
  };

  //Last accessed date - not used - keep for now
  // const getLastAccessedLabel = (date: number) => {
  //   let label: string;
  //   if (dayjs.duration(dayjs().startOf('day').diff(dayjs(date).startOf('day'))).asDays() <= 1) {
  //     label = dayjs().add(-1, 'day').day() === dayjs(date).day() ? t('Accessed yesterday') : t('Accessed today');
  //   } else {
  //     label = t('Last accessed {{lastAccessedDate}}', { lastAccessedDate: dayjs(date).format('LL') });
  //   }
  //   return label;
  // };

  const verticalCardFooterProps = useMemo(() => {
    const footerProps: CardBottomSectionProps | undefined = {};
    const duration = formatDuration(course);
    if (duration) {
      footerProps.disabled = false;
      footerProps.noteLeft = duration;
      footerProps.noteLeftIcon = <SystemIcons.Time size="16px" />;
    }
    if (!contentLanguage?.toLowerCase()?.includes(course._lang.toLowerCase())) {
      footerProps.noteRight = getLanguageByCode(languages, course._lang);
      footerProps.noteRightIcon = <SystemIcons.Language size="16px" />;
    }

    return Object.values(footerProps).some((val) => !!val) ? footerProps : undefined;
  }, [course]);

  const getVerticalCard = () => {
    return (
      <StyledVerticalCard
        hasCategoryLabel={categoryProps != null}
        hasTimeLabel={!!verticalCardFooterProps}
        className={customClassName ? `${customClassName} courseCard` : 'courseCard'}
        key={course._id}
        onCardClicked={() => openModal(course)}
        disabled={false}
        maxWidth={'100%'}
        topSectionProps={{
          ...getVerticalCardRibbonProps(course.order),
          ...getHeaderTagLabel(course),
          image: {
            src: course.image?.asset?._ref ? urlFor(course.image?.asset?._ref).url()! : course_fallback,
            alt: course.title,
          },
        }}
        middleSectionProps={{
          disabled: false,
          title: t(course.title),
          ...categoryProps,
        }}
        bottomSectionProps={verticalCardFooterProps}
      />
    );
  };

  const getHorizontalCard = () => {
    return (
      <StyledHorizontalCard
        image={{
          src: course.image?.asset?._ref ? urlFor(course.image?.asset?._ref).url()! : course_fallback,
          alt: course.title,
        }}
        title={course.title}
        tags={getHorizontalCourseTags(course, languages) as HorizontalCardTag[]}
        key={course._id}
        action={() => openModal(course)}
        variant={'elevated'}
      />
    );
  };

  return cardType === 'horizontal' ? getHorizontalCard() : getVerticalCard();
};
