import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownFilter,
  InputLabel,
  InputWrapper,
  ModalDialog,
  Size,
  ToastContext,
} from '@laerdal/life-react-components';

import { useSelector } from 'react-redux';
import { useDynamicSize } from '../../../hooks/useDynamicSize';
import { selectLanguages } from '../../../store/slice/settings';
import store, { AppDispatch } from '../../../store';
import { ToastType, capitalizeFirstLetter, getToastConfig } from '../../../helpers/utils';
import { selectUserContentLanguage, setContentLanguage } from '../../../store/slice/user';
import { UserApi } from '../../../api';
import { UserPreferences } from 'typescript';

type InviteUserProps = {
  closeModal: any;
  isModalOpen: boolean;
};

export const ContentLanguageModal: React.FunctionComponent<InviteUserProps> = ({ closeModal, isModalOpen }) => {
  const { t } = useTranslation('Common');
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>();
  const [requestInPendingState, setRequestPendingState] = useState(false);
  const languages = useSelector(selectLanguages);
  const size = useDynamicSize();
  const contentLanguage = useSelector(selectUserContentLanguage);
  const { addToast } = useContext(ToastContext);
  const dispatch = store.dispatch as AppDispatch;

  const selectedContentLanguage = useMemo(() => {
    if (!!languages && !!contentLanguage) {
      const localeFormatArray = contentLanguage?.split('-');
      const isoLanguage = localeFormatArray[0];
      //as the default org language can be different than the supported UI/Content languages
      //exclude the variant subtag to select the proper on
      const locale = languages.find((l) => l.locale === contentLanguage)?.locale;
      return locale ?? languages.find((lang) => lang.locale.includes(isoLanguage))?.locale;
    }
  }, [contentLanguage, languages]);

  const closeModalAndClearInput = () => {
    closeModal();
    setSelectedLanguage(undefined);
  };

  const saveLanguage = () => {
    setRequestPendingState(true);
    UserApi.SetCurrentUserPreferences({
      contentLanguage: selectedLanguage ?? selectedContentLanguage,
    } as UserPreferences).then((response) => {
      response
        ? addToast(t('Changed content language'), getToastConfig(ToastType.Success))
        : addToast(t('An error occured. Please restart the process.'), getToastConfig(ToastType.Error));
      setRequestPendingState(false);
      dispatch(setContentLanguage(selectedLanguage!));
      closeModalAndClearInput();
    });
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      closeModalAndClearInput={closeModalAndClearInput}
      closeAction={closeModalAndClearInput}
      title={t('Preferences')}
      submitAction={saveLanguage}
      size={size}
      buttons={[
        {
          id: 'cancel',
          variant: 'tertiary',
          text: t('Cancel'),
          action: closeModalAndClearInput,
        },
        {
          id: 'submit',
          action: saveLanguage,
          text: t('Save changes'),
          variant: 'primary',
          loading: requestInPendingState,
        },
      ]}>
      <InputWrapper>
        <InputLabel inputId="contentLanguageDropdown" text={t('Content language')}></InputLabel>
        <DropdownFilter
          scrollable={true}
          size={Size.Small}
          required={true}
          list={
            languages?.map((lang) => ({ displayLabel: capitalizeFirstLetter(lang.name), value: lang.locale })) ?? []
          }
          id="contentLanguageDropdown"
          onSelect={(value: string) => setSelectedLanguage(value)}
          value={selectedLanguage ?? selectedContentLanguage}
        />
      </InputWrapper>
    </ModalDialog>
  );
};
