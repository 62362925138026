import { globalStyles } from '@laerdal/life-react-components';
import { Route, Routes } from 'react-router';
import { createGlobalStyle } from 'styled-components';
import { LocalGlobalStyling } from './LocalStyles';
import { OverviewPage } from './pages/Overview/Overview';
import SingleCourse from './pages/Ecl/components/SingleCourse';
import { NoOrganizationPage } from './pages/NoOrganization';
import { AuthorizedRoutes } from './components/common/AuthorizedRoutes';
import { useAuthentication } from './hooks/useAuthentication';
import { useChangeOrganization } from './hooks/useChangeOrganization';
import { Layout } from './components/common/Layout';
import { UnauthenticatedHandler } from './components/common/UnauthenticatedHandler';
import { AuthenticatedRoutes } from './components/common/AuthenticatedRoutes';
import { useLocalization } from './hooks/useLocalization';
import Helmet from 'react-helmet';
import { selectLocale } from './store/slice/user';
import { useSelector } from 'react-redux';
import InvitationPage from './pages/Invitation/InvitationPage';
import { useSignInOnBehalf } from './helpers/signInOnBehalf-hoc';
import { useAppCues } from './hooks/useAppCues';
import { NoOrganizationRoute, SingleCourseRoute } from './constants';
import { ExternalInvitationPage } from './pages/Invitation/ExternalInvitationPage';
import { useHotJar } from './hooks/useHotJar';
import Login from './components/common/Login';
import CoursePage from './pages/Ecl/CoursePage';
import { useSwitcher } from './hooks/useSwitcher';

const GlobalStyle = createGlobalStyle`
${globalStyles}
${LocalGlobalStyling}
`;

function App() {
  useHotJar();
  useSignInOnBehalf();
  useAuthentication();
  useLocalization();
  useSwitcher();
  useChangeOrganization();
  useAppCues();

  const locale = useSelector(selectLocale);

  return (
    <>
      <Helmet>
        <html lang={locale || 'en'} />
        {/* Load Smartling Context Capturing Script */}
        {process.env.REACT_APP_GIGYA_ENV?.toLowerCase() === 'test' && (
          <script>{`(function (w, o) {
              try { 
                var h = document.getElementsByTagName('head')[0];
                var s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = 1;
                s.src = '//d2c7xlmseob604.cloudfront.net/tracker.min.js';
                s.onload = function () {
                  w.SmartlingContextTracker.init({ orgId: o });
                };
                h.insertBefore(s, h.firstChild);
              } catch (ex) { }
            })(window, '${process.env.REACT_APP_SMARTLING_ORGANIZATION_ID}')`}</script>
        )}
        {/* Load appcues Script */}
        {process.env.REACT_APP_APPCUES_ACCOUNTID && process.env.REACT_APP_APPCUES_ACCOUNTID.length > 0 && (
          <script
            type="text/javascript"
            src={`//fast.appcues.com/${process.env.REACT_APP_APPCUES_ACCOUNTID}.js`}></script>
        )}
      </Helmet>
      <GlobalStyle />
      <Layout>
        <UnauthenticatedHandler>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="join/:code" element={<InvitationPage />} />
            <Route path="/invite" element={<ExternalInvitationPage />} />
            <Route element={<AuthenticatedRoutes />}>
              <Route key="/no-organization" path={NoOrganizationRoute.path} element={<NoOrganizationPage />} />
              <Route element={<AuthorizedRoutes />}>
                <Route path="/" element={<OverviewPage />} />
                <Route key={SingleCourseRoute.path} path={SingleCourseRoute.path} element={<CoursePage />} />
              </Route>
            </Route>
          </Routes>
        </UnauthenticatedHandler>
      </Layout>
    </>
  );
}

export default App;
