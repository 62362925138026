import {
  BREAKPOINTS,
  ChipInput,
  COLORS,
  ComponentTextStyle,
  ComponentXSStyling,
  InputLabel,
  ModalDialog,
  Size,
  States,
  SystemIcons,
  ToastContext,
} from '@laerdal/life-react-components';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { InviteApi } from '../../../api';
import { NavigationTrackingEventName, TrackingEventType } from '../../../helpers/tracking';
import { getToastConfig, ToastType, validEmail } from '../../../helpers/utils';
import { selectUserId } from '../../../store/slice/user';
import { useDynamicSize } from '../../../hooks/useDynamicSize';
import { useMediaMatch } from 'rooks';
import { selectOrganization } from '../../../store/slice/organization';
import posthog from 'posthog-js';

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

const InviteUsersContentDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeedbackDiv = styled.div`
  display: flex;
  ${ComponentXSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)};
  align-items: center;
  gap: 4px;
`;

const ModalContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InviteUsersModal = ({ showModal, closeModal }: Props) => {
  const { t } = useTranslation('InviteUsers');
  const [emails, setEmails] = useState<string[]>([]);
  const [validForm, setValidForm] = useState<boolean | undefined>(false);
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const [validationEmailIndexes, setValidationEmailIndexes] = useState<boolean[]>([]);
  const [partialErrorOccured, setPartialErrorOccured] = useState<boolean>(false);
  const userId = useSelector(selectUserId);
  const organization = useSelector(selectOrganization);
  const { addToast } = useContext(ToastContext);
  const laerdalTracking = window.LaerdalTracking;
  const size = useDynamicSize();

  const onChangeValues = (emails: string[]) => {
    //remove empty email chips
    const emailAddresses = emails.map((email: string) => email.replace(/\s+/g, ''));
    setEmails(emailAddresses);

    let validationIndexes: boolean[] = [];
    emails.forEach((email, index) => {
      validationIndexes.push(validEmail(email));
    });
    setValidationEmailIndexes(validationIndexes);
    if (validationIndexes.some((index) => !index) || !emails.length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  };

  const onSave = () => {
    InviteApi.InviteUsersToOrganization(emails)
      .then((successfullySentInvites: string[]) => {
        if (successfullySentInvites.length < emails.length) {
          addToast(t('Error occured while sending one or multiple invitation emails'), getToastConfig(ToastType.Error));
          let validationIndexes: boolean[] = [];
          emails.forEach((email, index) => {
            validationIndexes.push(successfullySentInvites.includes(email));
          });
          setPartialErrorOccured(true);
          setValidationEmailIndexes(validationIndexes);
          setValidForm(false);
        } else {
          let trackingEvent = laerdalTracking?.generateTrackingData(
            TrackingEventType.Navigation,
            NavigationTrackingEventName.SHARED_LIBRARY,
            { userID: userId, countryId: organization?.address?.country?.codeAlpha2 },
          );
          laerdalTracking?.track(trackingEvent);
          posthog.capture(`KH Library Shared`);
          addToast(t('Invitation sent'), getToastConfig(ToastType.Success, <SystemIcons.CheckMark size="24px" />));
          setValidationEmailIndexes([]);
          closeModal();
        }
      })
      .catch((err) => {
        addToast(t('Error occured while sending invitation emails'), getToastConfig(ToastType.Error));
        setValidForm(false);
      });
  };

  useEffect(() => {
    setEmails([]);
    setValidationEmailIndexes([]);
    setValidForm(false);
    setPartialErrorOccured(false);
  }, [showModal]);

  return (
    <ModalDialog
      isModalOpen={showModal}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      title={t('Share library')}
      submitAction={() => {}}
      size={size}
      contentOverflow="auto"
      buttons={[
        {
          id: 'cancel',
          variant: 'tertiary',
          text: t('Cancel'),
          action: () => {
            closeModal();
          },
        },
        {
          id: 'sendInvite',
          action: () => {
            onSave();
          },
          disabled: !validForm,
          text: t('Send invite'),
          variant: 'primary',
        },
      ]}>
      <ModalContentDiv>
        <p>
          {t(
            'Invite co-workers to register and receive access to KnowledgeHub courses available through your organization.',
          )}
        </p>
        <InviteUsersContentDiv>
          <InputLabel inputId="inviteUsers" text={t('Email address')} />
          <ChipInput
            variants={validationEmailIndexes.map((x) => (x ? States.Default : States.Invalid))}
            required
            inputId="inviteUsers"
            values={emails}
            placeholder={t('Type to add more people')}
            validationMessage={
              validForm || !validationEmailIndexes.length
                ? undefined
                : t('Please check that all email addresses are valid')
            }
            onValueChange={onChangeValues}
            multiLine={true}
          />
          {(validForm || (!validationEmailIndexes.length && !partialErrorOccured)) && (
            <FeedbackDiv>
              <SystemIcons.Information size="20px" />
              <div>{t('Use commas or spaces to separate multiple email addresses')}</div>
            </FeedbackDiv>
          )}
        </InviteUsersContentDiv>
      </ModalContentDiv>
    </ModalDialog>
  );
};
