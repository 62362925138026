import { LoadingIndicator } from '@laerdal/life-react-components';
import { useEffect, useRef, useState } from 'react';
import { CourseContainer, LoadingContainer } from '../styles';
import EclApi from '../../../api/EclApi';

interface Props {
  courseCode: string | undefined;
  courseLang: string | undefined;
  courseUrl: string | undefined;
  userId: string | undefined;
  userLastLogin: number | undefined;
}

export const EmbeddedCourse = ({ courseUrl, userId, courseCode, courseLang, userLastLogin }: Props) => {
  const [contentLoading, setContentLoading] = useState<boolean>(true);

  const ref = useRef<HTMLEmbedElement | null>(null);

  useEffect(() => {
    if (ref.current && contentLoading && courseUrl && courseUrl.length > 0) {
      ref.current.src = `${courseUrl}?external_user_id=${userId}&external_attempt_id=${userLastLogin}`;
      ref.current.onload = () => {
        setContentLoading(false);
      };
      ref.current.onerror = (err) => {
        console.log('Loading course failed', err);
      };
    }
  }, [courseUrl, contentLoading, ref.current]);

  useEffect(() => {
    if (courseCode && courseLang && !contentLoading) {
      EclApi.SetCourseProgress(courseCode, courseLang);
    }
  }, [courseCode, courseLang, contentLoading]);

  return (
    <>
      {(contentLoading || !courseUrl) && (
        <LoadingContainer>
          <LoadingIndicator></LoadingIndicator>
        </LoadingContainer>
      )}
      <CourseContainer id="course-container" ref={ref} data-hj-allow-iframe=""></CourseContainer>
    </>
  );
};
