import { ContentIcons } from '@laerdal/life-react-components';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectSanityCourseLabels } from '../../../store/slice/courses';
import { selectLocale } from '../../../store/slice/user';
import { OptionSanity, EclCourseSanity } from '../../../types';

export enum LabelTypeValues {
  online_course = 'online_course',
  onboarding = 'onboarding',
  technical = 'technical',
}

export const useCourseLabels = () => {
  const appLocale = useSelector(selectLocale);
  const sanityEclCourseLabels = useSelector(selectSanityCourseLabels);

  const getAllLabelsByLocale = useCallback(() => {
    let localeLabels: OptionSanity[] = [];
    if (appLocale && sanityEclCourseLabels) {
      const defaultLang = process.env.REACT_APP_DEFAULT_LANGUAGE;
      const lang = appLocale || process.env.REACT_APP_DEFAULT_LANGUAGE;
      const keyLocale = Object.keys(sanityEclCourseLabels)?.find((x) => x.toLowerCase().includes(lang!.toLowerCase()));
      localeLabels = keyLocale ? sanityEclCourseLabels[keyLocale] : sanityEclCourseLabels[defaultLang!];
    }
    return localeLabels;
  }, [appLocale, sanityEclCourseLabels]);

  const getCourseLabel = useCallback(
    (courseLabel: string) => {
      const localeLabels = getAllLabelsByLocale();
      return localeLabels?.find((label) => label.labelValue === courseLabel);
    },
    [appLocale, sanityEclCourseLabels],
  );

  const getCategoryIconByLabelType = function (valueType: LabelTypeValues) {
    switch (valueType) {
      case LabelTypeValues.onboarding:
        return <ContentIcons.Learner size="32px" />;
      case LabelTypeValues.online_course:
        return <ContentIcons.ELearning size="32px" />;
      case LabelTypeValues.technical:
        return <ContentIcons.Maintenance size="32px" />;
    }
  };

  const getCategoryLabelProps = (course: EclCourseSanity) => {
    const label = getCourseLabel(course.label);

    return label
      ? {
          categoryLabel: label.labelText,
          categoryIcon: getCategoryIconByLabelType(label.labelValue! as LabelTypeValues),
        }
      : null;
  };

  return {
    getAllCourseLabelsByLocale: getAllLabelsByLocale,
    getCourseLabel,
    getCategoryLabelProps,
    sanityEclCourseLabels,
  };
};
