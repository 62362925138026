import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableOrganizations } from '../store/slice/organization';
import { selectIsAuthorized, selectSwitchOrganizationEnabled } from '../store/slice/user';
import { CallbackParams } from '@laerdal/navigation';
import { ToastColor, ToastContext, ToastPosition } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';

export const useChangeOrganization = () => {
  const availableOrganizations = useSelector(selectAvailableOrganizations);
  const userAuthorized = useSelector(selectIsAuthorized);
  const { t } = useTranslation('Common');
  const { addToast } = useContext(ToastContext);
  const canChangeOrg = useSelector(selectSwitchOrganizationEnabled);

  useEffect(() => {
    //display toast message once the organization has been changed and the page has been reloaded
    if (availableOrganizations) {
      let changedOrgId = sessionStorage.getItem('organizationChanged');
      if (changedOrgId) {
        const currentOrg = availableOrganizations?.find((org) => org.id === changedOrgId);
        sessionStorage.removeItem('organizationChanged');
        addToast(t('Signed in to {{OrganizationName}}', { OrganizationName: currentOrg?.name }), {
          color: ToastColor.GREEN,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableOrganizations]);

  useEffect(() => {
    window.globalNav?.setCanSwitchOrganization(canChangeOrg || false);
  }, [canChangeOrg]);

  useEffect(() => {
    const onChangeOrganization = (arg: CallbackParams) => {
      sessionStorage.setItem('organizationChanged', arg.entityId);
      window.location.reload();
    };
    //user should be authorized in order to get the notification
    if (userAuthorized) {
      //subscribe to 'notifyOrganizationChanged' event
      // @ts-ignore
      window.globalNav?.emitter?.subscribe('notifyOrganizationChanged', onChangeOrganization);
    }
    return () => {
      //unsubscribe to 'notifyOrganizationChanged' event
      // @ts-ignore
      window.globalNav?.emitter?.removeEventSubscriber('notifyOrganizationChanged', onChangeOrganization);
    };
  }, [userAuthorized]);
};
