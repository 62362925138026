import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectIsAuthenticated } from '../../store/slice/user';
import Login from './Login';

export const AuthenticatedRoutes: React.FunctionComponent = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return isAuthenticated ? <Outlet /> : <Login />;
};
