import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SettingsApi } from '../../../api';
import { Language, Market } from '../../../types';
import { AppDispatch, RootState } from '../../index';
import { SettingsSliceState } from './types';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    languages: [],
    markets: [],
  } as SettingsSliceState,
  reducers: {
    resetState: (state, _action: AnyAction) => {
      state.languages = [];
    },
    setLanguages: (state, action: PayloadAction<Language[] | undefined>) => {
      state.languages = action.payload;
    },
    setMarkets: (state, action: PayloadAction<Market[] | undefined>) => {
      state.markets = action.payload;
    },
  },
});

export const { resetState, setLanguages, setMarkets } = settingsSlice.actions;

export const fetchLanguages = () => (dispatch: AppDispatch) => {
  SettingsApi.GetLanguages(dispatch, setLanguages).catch((error: any) => console.error(error));
};

export const fetchMarkets = () => (dispatch: AppDispatch) => {
  SettingsApi.GetMarkets(dispatch, setMarkets).catch((error: any) => console.error(error));
};

export const selectLanguages = (state: RootState) => state?.settings?.languages;
export const selectMarkets = (state: RootState) => state?.settings.markets;

export default settingsSlice.reducer;
