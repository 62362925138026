import { features } from 'process';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppCuesFlowEventName, TrackingEventType } from '../helpers/tracking';
import i18n from '../i18n/config';
import { useCourseDataHelpers } from '../pages/Ecl/hooks/CourseDataHelper';
import { isFeatureActivated, selectFeatures } from '../store/slice/features';
import { selectOrganization } from '../store/slice/organization';
import {
  selectCompanyRoles,
  selectCountry,
  selectDateRegistered,
  selectHasSignedInOnBehalf,
  selectIsAuthenticated,
  selectIsAuthorized,
  selectLastLogin,
  selectUserId,
} from '../store/slice/user';

interface AppCues {
  identify: (identityId: string, userInfo: any) => void;
  page: () => void;
  reset: () => void;
  track: (event: string, data: any) => void;
}

declare global {
  interface Window {
    Appcues: AppCues | null;
  }
}

export const useAppCues = () => {
  const identityId = useSelector(selectUserId);
  const country = useSelector(selectCountry);
  const features = useSelector(selectFeatures);
  const organization = useSelector(selectOrganization);
  const userDateRegistered = useSelector(selectDateRegistered);
  const userLastLogin = useSelector(selectLastLogin);
  const companyRoles = useSelector(selectCompanyRoles);
  const isSignedInOnBehalf = useSelector(selectHasSignedInOnBehalf);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userAuthorized = useSelector(selectIsAuthorized);
  const location = useLocation();
  const { hasActiveECLSubscription } = useCourseDataHelpers();

  /**
   * Notify AppCues of page change
   * */
  useEffect(() => {
    window.Appcues && window.Appcues.page();
  }, [location]);

  useEffect(() => {
    const appcuesEventChangedOrg = sessionStorage.getItem('appcues_org_changed');
    if (
      isFeatureActivated('APPCUES_ENABLED', features) &&
      !!window.LaerdalTracking &&
      !!appcuesEventChangedOrg &&
      isAuthenticated &&
      userAuthorized
    ) {
      sessionStorage.removeItem('appcues_org_changed');
      const trackingEvent = window.LaerdalTracking?.generateTrackingData(
        TrackingEventType.AppCuesFlow,
        AppCuesFlowEventName.ORGANIZATION_CHANGED,
        {},
      );
      window.LaerdalTracking?.track(trackingEvent);
    }
  }, [features, isAuthenticated, userAuthorized, window.LaerdalTracking]);

  /**
   * AppCues identify event can be called multiple times to populate the user info
   * */
  useEffect(() => {
    if (isFeatureActivated('APPCUES_ENABLED', features) && !isSignedInOnBehalf && organization) {
      if (identityId && !!window?.Appcues) {
        const userInfo = {
          created: userDateRegistered,
          location: country,
          locale: i18n.language,
          organization: organization?.name,
          lastLogin: userLastLogin, // would this count for last activity
          companyRoles: companyRoles?.map((a) => a.name)?.join(','),
          activeSubscription: hasActiveECLSubscription(),
        };

        window.Appcues.identify(identityId, userInfo);
      }
    }
  }, [
    features,
    userDateRegistered,
    identityId,
    country,
    i18n.language,
    organization,
    userLastLogin,
    companyRoles,
    isSignedInOnBehalf,
  ]);
};
