import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from '../store';
import { Feature, FeatureRuleDto } from '../types';
import AuthApi from './AuthApi';

class FeatureApi {
  GetFeatures = async (dispatch: AppDispatch, setFeatures: ActionCreatorWithPayload<Feature[] | undefined, string>): Promise<Feature[] | void> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/features`)
      .then((response) => {
        let data: Feature[] = response.data;
        dispatch(setFeatures(data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  HasAccessRule = async (featureRule: FeatureRuleDto): Promise<boolean> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/features/checkFeatureRule`, featureRule, { headers: { Authorization: `Bearer ${await AuthApi.getToken()}` } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export default new FeatureApi();
