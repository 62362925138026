import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { AppDispatch } from '../../store';
import { fetchFeatureToggle } from '../../store/slice/features';
import { fetchLanguages, fetchMarkets } from '../../store/slice/settings';
import { selectIsAuthenticated, selectLocale } from '../../store/slice/user';
import { GlobalLoader } from './GlobalLoader';

export const UnauthenticatedHandler: React.FunctionComponent<any> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = store.dispatch as AppDispatch;

  useEffect(() => {
    dispatch(fetchFeatureToggle());
    dispatch(fetchLanguages());
    dispatch(fetchMarkets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated === undefined) {
    return <GlobalLoader />;
  }

  return children;
};
