import { AccountsGetJwtData } from '@laerdal/navigation';
import axios from 'axios';
import JwtDecode from 'jwt-decode';
import { Jwt } from '../types';

class AuthApi {
  token = '';
  static instance = new AuthApi();

  constructor() {
    axios.defaults.headers.patch['Content-Type'] = 'application/json';
  }

  /**
   * Retrieves the JWT token from the Gigya REST API and stores it.
   */
  retrieveAndStoreJWT = () => {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      window.gigya.accounts.getJWT({
        fields: 'firstName, lastName, email, data.organization.country, data.commerce.market',
        expiration: process.env.REACT_APP_JWT_LIFETIME ?? 3600,
        callback: (res: AccountsGetJwtData) => {
          if (res.errorCode === 0) {
            this.token = res.id_token;
            resolve(true);
          } else {
            reject();
          }
        },
      });
    });
  };

  /**
   * Retrieves the token from the context.
   * @returns A JWT token for the currently authenticated user.
   */
  getToken = async (): Promise<string> => {
    // Let's check if token exists
    if (this.token) {
      // Let's check if we need to renew token
      const jwt: Jwt = JwtDecode(this.token);

      if (jwt?.exp && Date.now() >= jwt.exp * 1000) {
        // Let's refresh the token
        await this.retrieveAndStoreJWT();
      }
    } else {
      await this.retrieveAndStoreJWT();
    }

    // Let's return the token
    return this.token;
  };
}

export default AuthApi.instance;
