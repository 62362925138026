import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { NoOrganizationRoute } from '../../constants';
import { selectOrganization } from '../../store/slice/organization';
import { selectIsAuthorized } from '../../store/slice/user';
import { GlobalLoader } from './GlobalLoader';

export const AuthorizedRoutes: React.FunctionComponent = () => {
  const userInitialized = useSelector(selectIsAuthorized);
  const organization = useSelector(selectOrganization);

  const render = () => {
    if (userInitialized === undefined) {
      return <GlobalLoader />;
    }

    if (!!userInitialized && !organization) {
      return <Navigate to={NoOrganizationRoute.path} />;
    }
    return <Outlet />;
  };

  return render();
};
