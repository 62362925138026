import { COLORS } from '@laerdal/life-react-components';
import { CourseDetails, CourseVisibilitySetting, Language } from '../../../types';
import { CourseSectionType, CourseSectionTypeValue } from '../EclCoursePage';
import {
  CourseCardsContainer,
  CourseSectionHeading,
  CourseSectionContainer,
  FreeCourseCardsContainer,
} from '../styles';
import { CourseCardItem } from './CourseCardItem';

interface Props {
  courses: CourseDetails[];
  title?: string;
  languages?: Language[];
  section?: CourseSectionTypeValue;
  activeSubscription?: boolean;
  isEclOrg: boolean;
  onOpenModal: (course: CourseDetails) => void;
}

export const LibrarySection = ({
  courses,
  section,
  activeSubscription,
  languages,
  onOpenModal,
  title,
  isEclOrg,
}: Props) => {
  const isFreeSection = section === CourseSectionType[CourseSectionType.Free] && !isEclOrg;

  const getCourseCardItems = (courses: CourseDetails[]) => {
    return courses.map((course, index) => (
      <CourseCardItem
        isEclOrg={isEclOrg}
        customClassName={
          section === CourseSectionType[CourseSectionType.MostRelevant] && index < 3 ? 'latest-accessed' : undefined
        }
        showExpireTag={
          activeSubscription != null &&
          !activeSubscription &&
          course.visibilitySettings === CourseVisibilitySetting.OneYearLimitedAccess
        }
        cardType={isFreeSection ? 'horizontal' : undefined}
        course={course}
        languages={languages}
        onOpenModal={onOpenModal}
      />
    ));
  };

  return (
    <CourseSectionContainer backgroundColor={isFreeSection ? COLORS.neutral_50 : undefined} usePadding={isFreeSection}>
      {title && <CourseSectionHeading>{title}</CourseSectionHeading>}
      {!isFreeSection ? (
        <CourseCardsContainer className={section?.toLocaleLowerCase()}>
          {getCourseCardItems(courses)}
        </CourseCardsContainer>
      ) : (
        <FreeCourseCardsContainer>{getCourseCardItems(courses)}</FreeCourseCardsContainer>
      )}
    </CourseSectionContainer>
  );
};
