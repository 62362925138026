import { useParams, useSearchParams } from 'react-router-dom';
import SingleCourse from './components/SingleCourse';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AppDispatch, useAppDispatch } from '../../store';
import { fetchCourseCodes, selectCourseCodes } from '../../store/slice/courses';
import Helmet from 'react-helmet';
import { AppName } from '../../constants';
import VersionedCourse from './components/VersionedCourse';
import { selectLastLogin, selectUserId } from '../../store/slice/user';
import { useCourseDataHelpers } from './hooks/CourseDataHelper';
import { selectOrganization } from '../../store/slice/organization';

const CoursePage = () => {
  const { id } = useParams<{ id: string }>();
  const [params] = useSearchParams();
  const eclcourses = useSelector(selectCourseCodes);
  const userId = useSelector(selectUserId);
  const lastLogin = useSelector(selectLastLogin);
  const dispatch = useAppDispatch() as AppDispatch;
  const { isEclOrganization } = useCourseDataHelpers();
  const organization = useSelector(selectOrganization);

  useEffect(() => {
    if (!eclcourses) {
      dispatch(fetchCourseCodes());
    }
  }, [eclcourses]);

  return (
    <>
      <Helmet>
        <title>{AppName}</title>
      </Helmet>
      <main id="main">
        {id ? (
          <SingleCourse
            isEclOrganization={isEclOrganization}
            lastLogin={lastLogin}
            userId={userId}
            courseId={id}
            eclCourses={eclcourses}
            organization={organization}
          />
        ) : (
          <div>
            <VersionedCourse
              isEclOrganization={isEclOrganization}
              lastLogin={lastLogin}
              userId={userId}
              eclCourses={eclcourses}
              courseVersion={params.get('v')}
              courseCode={params.get('code')}
              organization={organization}
            />
          </div>
        )}
      </main>
    </>
  );
};

export default CoursePage;
