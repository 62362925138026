import React, { useEffect } from 'react';
import { LoadingIndicator } from '@laerdal/life-react-components';

import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const LoadingMain = styled.main`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const LoadingIndicatorContainer = styled.div`
  margin: 0 auto;
`;

const InvitationPage = () => {
  const params = useParams<{ code: string }>();

  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_IDENTITY_URL}/join/organization/${params.code}?returnUrl=${window.location.origin}`;
  }, []);

  return (
    <LoadingMain>
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    </LoadingMain>
  );
};

export default InvitationPage;
