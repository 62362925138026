import { useEffect, useMemo, useState } from 'react';
import { GetCoursesByCode } from '../../../api/sanity';
import { Course, EclCourseSanity, EclSanityCourseVersion } from '../../../types';
import { EmbeddedCourse } from './EmbeddedCourse';
import { useNavigate } from 'react-router';
import { Organization } from '@laerdal/navigation';
import { selectUserContentLanguage } from '../../../store/slice/user';
import { useSelector } from 'react-redux';
import { useCourseDataHelpers } from '../hooks/CourseDataHelper';

interface Props {
  courseCode: string | null;
  courseVersion: string | null;
  userId: string | undefined;
  eclCourses: Course[] | undefined;
  lastLogin: Date | undefined;
  isEclOrganization: boolean;
  organization: Organization | null | undefined;
}

const VersionedCourse = ({ eclCourses, userId, courseCode, courseVersion, lastLogin, isEclOrganization }: Props) => {
  const [sanityCourse, setSanityCourse] = useState<EclCourseSanity>();
  const [sanityCourseVersion, setSanityCourseVersion] = useState<EclSanityCourseVersion>();
  const navigate = useNavigate();
  const contentLanguage = useSelector(selectUserContentLanguage);
  const { isCourseSupportedInUserLocale } = useCourseDataHelpers();

  const getCourseVersion = (sanityCourse: EclCourseSanity, version: string): EclSanityCourseVersion | null => {
    let courseVersionsMajorVersion = sanityCourse.versionList.filter((v) => v.versionNumber.startsWith(version));

    if (!courseVersionsMajorVersion?.length) {
      return null;
    }
    //only one version found starting witht the major specified version
    if (courseVersionsMajorVersion?.length == 1) {
      return courseVersionsMajorVersion[0];
    }
    //if multiple versions found starting with the specified major version
    //sort and get the highest
    var latestVersion = courseVersionsMajorVersion
      .sort((a, b) => compareVersions(a.versionNumber, b.versionNumber))
      .pop();
    return latestVersion!;
  };

  const compareVersions = (v1: string, v2: string) => {
    let v1Array = v1.split('.');
    let v2Array = v2.split('.');
    var longestLength = Math.max(v1Array.length, v2Array.length);
    for (var i = 0; i < longestLength; i++) {
      let versionPart1 = v1Array[i];
      let versionPart2 = v2Array[i];
      if (versionPart1 != versionPart2) {
        return parseInt(versionPart1 ?? 0) > parseInt(versionPart2 ?? 0) ? 1 : -1;
      }
    }
    return 0;
  };

  //get course by course code
  useEffect(() => {
    if (courseCode && courseVersion) {
      GetCoursesByCode(courseCode).then((courses: EclCourseSanity[]) => {
        const courseInContentLanguage = courses.find((c) => isCourseSupportedInUserLocale(c._lang));
        const enFallbackCourse = courses.find((c) => c._lang === 'en');

        let course: EclCourseSanity | undefined = undefined;
        let sanityCourseVersion: EclSanityCourseVersion | null = null;

        if (!!courseInContentLanguage) {
          const courseInContentLanguageVersion = getCourseVersion(courseInContentLanguage, courseVersion);
          course = courseInContentLanguage;
          sanityCourseVersion = courseInContentLanguageVersion;
        }

        //if no version found for the content language fallback to en
        if (!sanityCourseVersion && !!enFallbackCourse) {
          const enCourseLanguageVersion = getCourseVersion(enFallbackCourse, courseVersion);
          course = enFallbackCourse;
          sanityCourseVersion = enCourseLanguageVersion;
        }

        if (!!course && !!sanityCourseVersion) {
          setSanityCourse(course);
          setSanityCourseVersion(sanityCourseVersion);
        } else {
          sessionStorage.setItem('courseVersionNotFound', course!._id);
          navigate('/');
        }
      });
    }
  }, [courseCode, courseVersion, contentLanguage]);

  const dataLoading = useMemo(
    () => !eclCourses || !sanityCourse || !sanityCourseVersion,
    [eclCourses, sanityCourse, courseVersion],
  );

  useEffect(() => {
    if (!dataLoading && !isEclOrganization && !eclCourses?.find((course) => course.courseCode === sanityCourse?.code)) {
      sessionStorage.setItem('courseVersionNotFound', sanityCourse!._id);
      navigate('/');
    }
  }, [dataLoading, isEclOrganization, eclCourses]);

  return (
    <EmbeddedCourse
      courseCode={sanityCourse?.code}
      courseLang={sanityCourse?._lang}
      courseUrl={dataLoading ? undefined : sanityCourseVersion!.courseUrl}
      userId={userId}
      userLastLogin={lastLogin ? new Date(lastLogin).getTime() : undefined}
    />
  );
};

export default VersionedCourse;
