import ecourses_no_org from '../../assets/ecourses_no_org.svg';
import {
  Banner,
  Button,
  PageWidth,
  Size,
  SystemIcons,
  ToastColor,
  ToastContext,
  ToastPosition,
} from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthorized, selectUserId } from '../../store/slice/user';
import { selectOrganization } from '../../store/slice/organization';
import { useNavigate } from 'react-router';
import {
  ActionsDiv,
  Heading,
  ImageContainer,
  InformationImage,
  LeftColumnDiv,
  NoOrgDetailsDiv,
  NoOrgDiv,
  RightColumnDiv,
  StepCircle,
  StepRow,
  StepsContainer,
} from './styles';
import { NavigationTrackingEventName, TrackingEventType } from '../../helpers/tracking';
import { PageHeader } from '../Ecl/components/PageHeader';
import { AppName } from '../../constants';
import posthog from 'posthog-js';

export const NoOrganizationPage = () => {
  const { t } = useTranslation(['Ecl', 'NoOrganization']);
  const guideInviteUsersToOrgLink = `https://laerdal.force.com/HelpCenter/s/article/How-to-invite-new-users-to-my-organization`;
  const { addToast } = useContext(ToastContext);
  const userAuthorized = useSelector(selectIsAuthorized);
  const organization = useSelector(selectOrganization);
  const userId = useSelector(selectUserId);
  const navigate = useNavigate();
  const laerdalTracking = window.LaerdalTracking;

  useEffect(() => {
    if (userAuthorized && organization) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthorized, organization]);

  useEffect(() => {
    if (userId) {
      let trackingEvent = laerdalTracking?.generateTrackingData(
        TrackingEventType.Navigation,
        NavigationTrackingEventName.PAGE_VIEW_NO_ORG,
        { userID: userId },
      );
      laerdalTracking?.track(trackingEvent);
      posthog.capture(`KH EmptyPageView Opened`);
    }
  }, [userId]);

  const goToGuide = () => {
    window.open(guideInviteUsersToOrgLink, '_blank', 'noopener,noreferrer');
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(guideInviteUsersToOrgLink);
    addToast(t(`Link copied to clipboard`, { ns: 'NoOrganization' }), {
      color: ToastColor.GREEN,
      showCloseButton: true,
      autoClose: true,
      position: ToastPosition.TOPMIDDLE,
    });
  };

  return (
    <main id="main" style={{ height: '100vh', display: 'table' }}>
      <PageWidth useMaxWidth={true} maxWidth={1600}>
        <PageHeader
          title={AppName}
          subtitle={t('Learn the basics of simulation and how to use your equipment efficiently.', { ns: 'Ecl' })}
        />
        <NoOrgDiv>
          <LeftColumnDiv>
            <ImageContainer>
              <InformationImage className="noOrgImg" src={ecourses_no_org}></InformationImage>
            </ImageContainer>
          </LeftColumnDiv>
          <RightColumnDiv>
            <NoOrgDetailsDiv>
              <Heading>{t('Want access to KnowledgeHub?', { ns: 'NoOrganization' })}</Heading>
              <p>
                {t('Follow these steps to get access to the courses at your organization', { ns: 'NoOrganization' })}
              </p>
              <StepsContainer>
                <StepRow>
                  <StepCircle>1</StepCircle>
                  <>
                    {t(`Contact the admin for your organization, and request access to KnowledgeHub`, {
                      ns: 'NoOrganization',
                    })}
                  </>
                </StepRow>
                <StepRow>
                  <StepCircle>2</StepCircle>
                  <>{t(`Copy the link for the guide`, { ns: 'NoOrganization' })}</>
                </StepRow>
                <StepRow>
                  <StepCircle>3</StepCircle>
                  <>{t(`Share the link for the guide with your admin`, { ns: 'NoOrganization' })}</>
                </StepRow>
              </StepsContainer>
              <ActionsDiv>
                <Button id="view-guide" variant="secondary" size={Size.Medium} onClick={goToGuide}>
                  {t(`View guide`, { ns: 'NoOrganization' })}
                </Button>
                <Button
                  id="copyLink"
                  variant="tertiary"
                  icon={<SystemIcons.Link size="24px" />}
                  onClick={copyLinkToClipboard}>
                  {t(`Copy link`, { ns: 'NoOrganization' })}
                </Button>
              </ActionsDiv>
              <Banner
                icon={<SystemIcons.Support size="24px" />}
                size={Size.Medium}
                link={'https://laerdal.force.com/HelpCenter/s/contactsupport'}
                linkText={t(`Contact support`)}>
                {t(`Don't know your admin?`, { ns: 'NoOrganization' })}
              </Banner>
            </NoOrgDetailsDiv>
          </RightColumnDiv>
        </NoOrgDiv>
      </PageWidth>
    </main>
  );
};
