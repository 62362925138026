import { useSelector } from 'react-redux';
import { selectPermissions } from '../store/slice/user';

export enum PermissionsType {
  Subscription = 'Subscription',
  AccountOwner = 'account.owner',
  ManageAccess = 'organization.manageaccess',
}

export const usePermissions = () => {
  const permissions = useSelector(selectPermissions);

  //this will be probably extended later
  const hasPermissions = (type: PermissionsType): boolean => {
    if (permissions?.includes(PermissionsType.AccountOwner)) {
      return true;
    }

    return !!permissions?.includes(type);
  };

  return { hasPermissions };
};
