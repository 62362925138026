import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from '../store';
import { Country, Language, Market } from '../types';

class SettingsApi {
  GetCountries = async (): Promise<Country[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/settings/countries`)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  GetLanguages = async (dispatch: AppDispatch, setLanguages: ActionCreatorWithPayload<Language[] | undefined, string>) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/settings/languages`)
      .then((response) => {
        let data: Language[] = response.data;
        dispatch(setLanguages(data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  GetMarkets = async (dispatch: AppDispatch, setMarkets: ActionCreatorWithPayload<Market[] | undefined, string>) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/settings/markets`)
      .then((response) => {
        let data: Market[] = response.data;
        dispatch(setMarkets(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export default new SettingsApi();
