import {
  SystemIcons,
  Toast,
  ToastActionType,
  ToastColor,
  ToastContext,
  ToastPosition,
  ToastContainer,
  ToastTextContainer,
  ToastActionButtons,
  BREAKPOINTS,
} from '@laerdal/life-react-components';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isFeatureActivated, selectFeatures } from '../../../store/slice/features';
import { UserApi } from '../../../api';
import { BannerType, UserBanner } from '../../../types';
import posthog from 'posthog-js';
import { useDynamicSize } from '../../../hooks/useDynamicSize';

interface Props {
  toastPrerequisitesMet: boolean;
}

const StyledToast = styled.div`
  ${ToastContainer} {
    //justify-content: space-between;
    width: 100%;
  }
  ${ToastTextContainer} {
    width: 100%;
  }
  ${ToastActionButtons} {
    margin-left: auto;
    justify-content: flex-end;
    flex-direction: column-reverse;
    ${BREAKPOINTS.MEDIUM} {
      flex-direction: row;
    }
  }
`;

const StyledContentDiv = styled.div`
  display: flex;
  gap: 8px;
  align-content: center;
  align-items: center;
`;

export const FreeTrialExperiment = ({ toastPrerequisitesMet }: Props) => {
  const { t } = useTranslation('FreeTrial');
  const { addToast } = useContext(ToastContext);
  const [experimentBanner, setExperimentBanner] = useState<UserBanner | undefined>(undefined);
  const [banners, setBanners] = useState<UserBanner[] | undefined>();
  const features = useSelector(selectFeatures);
  const screenSize = useDynamicSize();

  useEffect(() => {
    UserApi.GetCurrentUserBanners(BannerType.FreeTrial, true).then((banners) => setBanners(banners));
  }, []);

  useEffect(() => {
    if (!!banners && banners.filter((banner) => !banner.isDeleted).length > 0 && toastPrerequisitesMet) {
      setExperimentBanner(banners[0]);
    }
  }, [banners, toastPrerequisitesMet]);

  useEffect(() => {
    if (
      toastPrerequisitesMet &&
      isFeatureActivated('SHOW_FREE_TRIAL_EXPERIMENT', features) &&
      !!banners &&
      !banners.length
    ) {
      UserApi.AddBanner(BannerType.FreeTrial).then((data) => setExperimentBanner(data));
    }
  }, [banners, features, toastPrerequisitesMet]);

  const RemoveUserBanner = (interested: boolean) => {
    posthog.capture(`KH free_trial_clicked`, {
      user_interested: interested,
    });
    UserApi.RemoveUserBanners(experimentBanner!.id, true).then((action) => {
      addToast(t('Thank you for sharing your feedback'), {
        color: ToastColor.BLUE,
        position: ToastPosition.TOPMIDDLE,
        icon: <SystemIcons.CheckMark size="24px" />,
        autoClose: true,
      });
    });
  };

  return !!experimentBanner && toastPrerequisitesMet ? (
    <StyledToast>
      <Toast
        key="a"
        remove={() => {}}
        options={{
          icon: <SystemIcons.Tip />,
          color: ToastColor.BLUE,
          position: ToastPosition.TOPMIDDLE,
          autoClose: false,
          action: [
            {
              label: t('Not interested'),
              type: ToastActionType.SECONDARY,
              handler: () => {
                setExperimentBanner(undefined);
                RemoveUserBanner(false);
              },
            },
            {
              label: t('Interested in free trail'),
              type: ToastActionType.PRIMARY,
              handler: () => {
                setExperimentBanner(undefined);
                RemoveUserBanner(true);
              },
            },
          ],
        }}
        content={
          <StyledContentDiv>
            {t(
              'Interested in a free KnowledgeHub subscription trial to access our full course library? Share your interest and help shape its future.',
            )}
          </StyledContentDiv>
        }
      />
    </StyledToast>
  ) : (
    <></>
  );
};
