import {
  BREAKPOINTS,
  ChoiceChips,
  ContentIcons,
  DropdownButton,
  DropdownFilter,
  DropdownItem,
  FilterChip,
  Size,
  SystemIcons,
} from '@laerdal/life-react-components';
import { ChipValue } from '@laerdal/life-react-components/dist/Chips/ChipTypes';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  DISPLAY_ALL_FILTER_VALUE,
  selectSanityCourseLabels,
  selectSimulatorOptions,
} from '../../../store/slice/courses';
import { CourseFilterCriteria } from '../../../store/slice/courses/types';
import { OptionSanity } from '../../../types';
import { isFeatureActivated, selectFeatures } from '../../../store/slice/features';

interface Props {
  selectedSortValue: SortOptions;
  filterCriteria: CourseFilterCriteria;
  availableSimulatorFilters: string[];
  availableCourseLabelFilters: string[];
  isEclOrganization: boolean;
  getAllCourseLabelsByLocale: () => OptionSanity[];
  onSortValueChanged: (sortOption: SortOptions) => void;
  onFilterValueChanged: (value: Partial<CourseFilterCriteria>) => void;
}

export enum SortOptions {
  Relevance = 'Relevance',
  TitleAscending = 'Title (A-Z)',
  TitleDescending = 'Title (Z-A)',
}

export enum FilterByAccessedDateOptions {
  All = 'All courses',
  //NewToYou = 'New to you',
  Opened = 'Opened',
}

const FiltersDiv = styled.div`
  div[role='radiogroup'] {
    display: flex;
    gap: 8px;
  }
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const FilterSortOptions = ({
  onSortValueChanged,
  onFilterValueChanged,
  filterCriteria,
  selectedSortValue,
  availableSimulatorFilters,
  availableCourseLabelFilters,
  isEclOrganization,
  getAllCourseLabelsByLocale,
}: Props) => {
  const { t } = useTranslation('Ecl');

  const simulatorOptions = useSelector(selectSimulatorOptions);
  const features = useSelector(selectFeatures);
  const localeCourseLabels = getAllCourseLabelsByLocale();

  const getSortOptionsDatasource = useCallback(() => {
    const sortDatasource = isEclOrganization
      ? Object.values(SortOptions).filter((val) => val !== SortOptions.Relevance)
      : Object.values(SortOptions);
    return sortDatasource.map((option) => {
      switch (option) {
        case SortOptions.Relevance:
          return { value: SortOptions.Relevance, displayLabel: t(SortOptions.Relevance) } as DropdownItem;
        case SortOptions.TitleAscending:
          return { value: SortOptions.TitleAscending, displayLabel: t(SortOptions.TitleAscending) } as DropdownItem;
        case SortOptions.TitleDescending:
          return { value: SortOptions.TitleDescending, displayLabel: t(SortOptions.TitleDescending) } as DropdownItem;
      }
    });
  }, [isEclOrganization]);

  const getFilterByAccessDatasource = useCallback(() => {
    return Object.values(FilterByAccessedDateOptions).map((option) => {
      switch (option) {
        case FilterByAccessedDateOptions.All:
          return { value: FilterByAccessedDateOptions.All, label: t(FilterByAccessedDateOptions.All) } as ChipValue;
        // case FilterByAccessedDateOptions.NewToYou:
        //   return {
        //     value: FilterByAccessedDateOptions.NewToYou,
        //     label: t(FilterByAccessedDateOptions.NewToYou),
        //   } as ChipValue;
        case FilterByAccessedDateOptions.Opened:
          return {
            value: FilterByAccessedDateOptions.Opened,
            label: t(FilterByAccessedDateOptions.Opened),
          } as ChipValue;
      }
    });
  }, []);

  const getSimulatorFilterOptionsDatasource = useCallback(() => {
    if (simulatorOptions && availableSimulatorFilters) {
      const availableOptions = simulatorOptions
        .filter((option) => availableSimulatorFilters.includes(option.labelValue))
        .map((option) => {
          return { value: option.labelValue, displayLabel: option.labelText };
        });
      availableOptions.unshift({ value: DISPLAY_ALL_FILTER_VALUE, displayLabel: t('All products') });
      return availableOptions;
    }
    return [];
  }, [simulatorOptions, availableSimulatorFilters]);

  const getCourseLabelsFilterOptionsDatasource = useCallback(() => {
    if (localeCourseLabels && availableCourseLabelFilters) {
      const availableOptions = localeCourseLabels
        .filter((option) => availableCourseLabelFilters.includes(option.labelValue))
        .map((option) => {
          return { value: option.labelValue, displayLabel: option.labelText };
        });
      availableOptions.unshift({ value: DISPLAY_ALL_FILTER_VALUE, displayLabel: t('All categories') });
      return availableOptions;
    }
    return [];
  }, [localeCourseLabels, availableCourseLabelFilters]);

  const sortOptions = getSortOptionsDatasource() as DropdownItem[];

  return (
    <FiltersDiv>
      <>
        {!isEclOrganization && (
          <ChoiceChips
            selected={filterCriteria.accessedDate ? filterCriteria.accessedDate : FilterByAccessedDateOptions.All}
            values={getFilterByAccessDatasource()}
            onClick={(chipValue) =>
              onFilterValueChanged({ accessedDate: chipValue.value as FilterByAccessedDateOptions })
            }
          />
        )}
      </>
      <div>
        {isFeatureActivated('SHOW_FILTER_BY_COURSE_LABEL', features) && (
          <DropdownButton
            className="filterCoursesByLabelBtn"
            width={'155px'}
            id="filterByLabel"
            type={'text'}
            items={getCourseLabelsFilterOptionsDatasource()}
            value={[filterCriteria.label ?? DISPLAY_ALL_FILTER_VALUE]}
            onClick={(values) => onFilterValueChanged({ label: values[0] })}
            alignLeft={true}
            maxHeight={'300px'}
            scrollable={true}
          />
        )}
        <DropdownButton
          className="filterCoursesBySimulatorBtn"
          width={'155px'}
          id="filterBySimulator"
          type={'text'}
          items={getSimulatorFilterOptionsDatasource()}
          value={[filterCriteria.simulator ?? DISPLAY_ALL_FILTER_VALUE]}
          onClick={(values) => onFilterValueChanged({ simulator: values[0] })}
          alignLeft={true}
          maxHeight={'300px'}
          scrollable={true}
        />
        <DropdownButton
          className="sortCoursesBtn"
          width={'155px'}
          type={'text'}
          items={sortOptions}
          value={[selectedSortValue]}
          multiSelect={false}
          onClick={(value) => onSortValueChanged(value[0] as SortOptions)}
          alignLeft={true}
        />
      </div>
    </FiltersDiv>
  );
};
