import React from 'react';
import { SiteFooter } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { SpacerDiv } from './styles';
import { FooterProps } from './types';
import { AppName } from '../../constants';

export const Footer: React.FunctionComponent<FooterProps> = ({ hasMarginTop }) => {
  const { t } = useTranslation('Common');

  return (
    <>
      {hasMarginTop && <SpacerDiv />}
      <SiteFooter
        id="NewFooterWithoutNav"
        sitename={AppName}
        showBetaTag={false}
        copyrightText={
          t('Copyright ©') + ' ' + new Date().getFullYear() + ' ' + t('Laerdal Medical. All Rights Reserved.')
        }
        includeNewsletterAndSocialSection={false}
        toTopText={t('TOP')}
        bottomLinks={[
          { label: t('Privacy Policy'), to: 'https://laerdal.com/support/customer-service/privacy-policy/' },
        ]}
      />
    </>
  );
};
