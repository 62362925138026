import { BREAKPOINTS } from '@laerdal/life-react-components';
import styled from 'styled-components';

export const SpacerDiv = styled.div`
  padding-bottom: 40px;

  ${BREAKPOINTS.MEDIUM} {
    padding-bottom: 80px;
  }
`;
