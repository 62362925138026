import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureApi } from '../../../api';
import LaerdalTracking from '../../../helpers/tracking';
import { Feature } from '../../../types';
import { AppDispatch, RootState } from '../../index';
import { FeaturesSliceState } from './types';

export const featuresSlice = createSlice({
  name: 'featureToggle',
  initialState: {
    features: [],
  } as FeaturesSliceState,
  reducers: {
    resetState: (state, _action: AnyAction) => {
      state.features = [];
      window.LaerdalTracking = null;
    },
    setFeatures: (state, action: PayloadAction<Feature[] | undefined>) => {
      state.features = action.payload;
      window.LaerdalTracking = new LaerdalTracking(isFeatureActivated('GOOGLE_ANALYTICS_ENABLED', action.payload), isFeatureActivated('APPCUES_ENABLED', action.payload));
    },
  },
});

export const { resetState, setFeatures } = featuresSlice.actions;

export const fetchFeatureToggle = () => (dispatch: AppDispatch) => {
  FeatureApi.GetFeatures(dispatch, setFeatures).catch((error: any) => console.error(error));
};

export const selectFeatures = (state: RootState) => state?.featuresToggle?.features;
export const isFeatureActivated = (name: string, features: Feature[] | undefined) => features?.find((feature) => feature.featureName === name)?.isEnabled;

export default featuresSlice.reducer;
