import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from '../store';
import { Course, CourseTracking } from '../types';
import AuthApi from './AuthApi';

class EclApi {
  GetCourseCodes = async (dispatch: AppDispatch, setCourseCodes: ActionCreatorWithPayload<Course[] | undefined, string>, fromCache: boolean = true): Promise<string[] | void> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/courses/list?fromCache=${fromCache}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        let data: Course[] = response.data as Course[];
        dispatch(setCourseCodes(data.map((d: Course) => d)));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  GetCourseTracking = async (dispatch: AppDispatch, setCourseTracking: ActionCreatorWithPayload<CourseTracking[] | undefined, string>): Promise<string[] | void> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/courses/track/userRelated`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        let data: CourseTracking[] = response.data;
        dispatch(setCourseTracking(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  GetCoursesByProductCode = async (productCode: string): Promise<Course[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/courses/${productCode}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        return response.data as Course[];
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  SetCourseProgress = async (code: string, lang: string): Promise<void> => {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/courses/track/`,
        { courseCode: code, courseLanguageCode: lang },
        {
          headers: {
            Authorization: `Bearer ${await AuthApi.getToken()}`,
          },
        },
      )
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export default new EclApi();
