import {
  BackButton,
  BREAKPOINTS,
  Card,
  CardMiddleSectionContainer,
  COLORS,
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  HorizontalCard,
  PageWidth,
} from '@laerdal/life-react-components';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 52px);

  ${BREAKPOINTS.SMALL} {
    height: calc(100vh - 60px);
  }

  ${BREAKPOINTS.LARGE} {
    height: calc(100vh - 68px);
  }

  align-items: center;
  justify-content: center;
`;

export const TopWrapper = styled.div<{ mobileView?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.mobileView ? 'column' : 'row')};
  justify-content: ${(props) => (props.mobileView ? 'start' : 'space-between')};
  align-items: ${(props) => (props.mobileView ? 'start' : 'center')};
  h2 {
    margin-top: 8px;
  }

  ${BREAKPOINTS.SMALL} {
    margin: 16px 0;
  }

  ${BREAKPOINTS.MEDIUM} {
    margin: 16px 0 16px 0;
  }

  ${BREAKPOINTS.LARGE} {
    margin: 32px 0 32px 0;
  }
`;

export const CourseSectionContainer = styled.div<{ backgroundColor?: string; usePadding?: boolean }>`
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : 'none')};
  padding: ${(props) => (props.usePadding ? '16px' : 'none')};
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${BREAKPOINTS.MEDIUM} {
    padding: ${(props) => (props.usePadding ? '24px' : 'none')};
    gap: 24px;
  }
  ${BREAKPOINTS.LARGE} {
    padding: ${(props) => (props.usePadding ? '32px' : 'none')};
    gap: 32px;
  }
`;

export const FreeCoursesDiv = styled.div`
  background-color: ${COLORS.neutral_50};
  padding-top: 32px;
  ${BREAKPOINTS.MEDIUM} {
    padding-top: 40px;
  }
`;

export const PaddingsDiv = styled.div`
  ${BREAKPOINTS.MEDIUM} {
    padding: 0 8px;
  }
  ${BREAKPOINTS.LARGE} {
    padding: 0px 64px;
  }
`;

export const FreeCourseCardsContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  ${BREAKPOINTS.MEDIUM} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    grid-gap: 32px;
  }
`;

export const CourseCardsContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin: 0;

  ${BREAKPOINTS.MEDIUM} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
  ${BREAKPOINTS.LARGE} {
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    > div {
      max-width: 384px;
    }
  }
`;

export const CourseSectionHeading = styled.div`
  margin-top: 8px;
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.neutral_700)}
`;

export const Title = styled.h1``;

export const Subtitle = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.black)}
  }
  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.black)}
  }
`;

export const NoCourses = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  background-color: ${COLORS.primary_100};
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-right: 32px;
`;

export const ModalTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  margin-bottom: 22px;
`;

export const ModalDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 15px;
`;

export const ModalFooter = styled.div`
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: row-reverse;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
`;

export const ModalHeader = styled.div`
  width: 100%;

  img {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 240px;
    margin-bottom: 8px;
    object-fit: cover;
  }
`;

export const BannerContainer = styled.div`
  .large {
    div {
      ${ComponentMStyling(ComponentTextStyle.Regular, null)}
    }
  }
`;

export const StyledVerticalCard = styled(Card)<{ hasTimeLabel: boolean; hasCategoryLabel: boolean }>`
  ${CardMiddleSectionContainer} {
    height: ${(props) => (props.hasTimeLabel ? '100px' : '156px')};
    .titleBlock {
      padding-top: ${(props) => (!props.hasCategoryLabel ? '40px' : '0px')};
    }
  }
`;
export const StyledHorizontalCard = styled(HorizontalCard)`
  height: 144px;
`;

/**
 * Single Course page specific styles
 */
export const TopWrapperCoursePage = styled.div`
  margin: 0 calc(calc(calc(calc(100vw - 100%) / 2) * -1) + 10px);
  padding: 16px calc(calc(calc(100vw - 100%) / 2) - 8px);
`;

export const TopBorderBottom = styled.div`
  position: absolute;
  padding-bottom: 16px;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${COLORS.neutral_100};
`;

export const CourseContainer = styled.embed`
  width: 100%;
  height: calc(100vh - 52px);

  ${BREAKPOINTS.SMALL} {
    height: calc(100vh - 60px);
  }

  ${BREAKPOINTS.LARGE} {
    height: calc(100vh - 68px);
  }
`;

export const StyledBackButton = styled(BackButton)`
  ${BREAKPOINTS.MEDIUM} {
    margin-top: 16px;
  }
`;

export const MessageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.neutral_100};
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 16px;
  h1 {
    margin: 24px 0 8px 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: ${COLORS.neutral_800};
  }

  p {
    margin: 0 0 24px 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 480px;
    text-align: center;
    color: ${COLORS.neutral_800};
  }
`;
