import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useSelector } from 'react-redux';
import { selectHasSignedInOnBehalf } from '../store/slice/user';

export const useHotJar = () => {
  const hasSignedInOnBehalf = useSelector(selectHasSignedInOnBehalf);

  useEffect(() => {
    // delay loading hotjar for 10 seconds to make sure that not signed in on behalf
    setTimeout(() => {
      if (
        !hasSignedInOnBehalf &&
        process.env.REACT_APP_ENVIRONMENT?.toLowerCase() !== 'development' &&
        process.env.REACT_APP_ENVIRONMENT?.toLowerCase() !== 'local' &&
        process.env.REACT_APP_HOTJAR_ID &&
        process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
      ) {
        const hjid: number = Number.parseInt(process.env.REACT_APP_HOTJAR_ID || '');
        const hjsv: number = Number.parseInt(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION || '');
        hotjar.initialize(hjid, hjsv);
      }
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSignedInOnBehalf]);
};
