import SignInOnBehalfService from '@laerdal/sign-in-on-behalf';
import { EclCourseSanity } from '../types';
import posthog from 'posthog-js';

export enum TrackingProvider {
  GoogleAnalytics,
  AppCues,
}

export enum TrackingEventType {
  Navigation = 'Navigation',
  AppCuesFlow = 'AppCues Flow',
}

export enum AppCuesFlowEventName {
  ORGANIZATION_CHANGED = 'Current Organization Changed',
}

export enum NavigationTrackingEventName {
  CLICK_ON_COURSE_PREVIEW = 'course_preview',
  CLICK_ON_OPEN_COURSE = 'open_course',
  CLICK_ON_CLOSE_COURSE_PREVIEW = 'close_course',
  NAVIGATE_BACK_TO_MY_COURSE = 'navigate_to_courses',
  PAGE_VIEW_NO_ORG = 'page_view_empty',
  SUBSCRIPTION_LEARN_MORE = 'subscription_learn_more',
  SHARED_LIBRARY = 'shared_library',
  USER_ONBOARDED = 'user_onboarded',
}

export const TrackingEventName = {
  ...NavigationTrackingEventName,
};
export type TrackingEventName = NavigationTrackingEventName | AppCuesFlowEventName;

export type TrackingEventData = {
  domain?: string;
  sourcePath?: string;
  eventCategory?: string;
  userID?: string;
  courseCode?: string;
  courseName?: string;
  courseLanguage?: string;
  customerNumber?: string;
  countryId?: string;
};

export type TrackingEvent = {
  name: TrackingEventName | string;
  data?: TrackingEventData;
};

//** Track navigation events specific to ecl courses */
export const trackCourseData = (
  eventName: NavigationTrackingEventName,
  course: EclCourseSanity | undefined,
  userId?: string,
  customerNumber?: string,
  countryId?: string,
) => {
  const laerdalTracking = window.LaerdalTracking;
  const trackingEvent = laerdalTracking?.generateTrackingData(TrackingEventType.Navigation, eventName, {
    courseCode: course?.code,
    courseLanguage: course?._lang,
    courseName: course?.title,
    userID: userId,
    customerNumber: customerNumber,
    countryId: countryId,
  });
  laerdalTracking?.track(trackingEvent);

  //@ts-ignore
  posthog.capture(`KH ${getPosthogEventNameByType(eventName)}`, {
    course_code: course?.code,
    course_language: course?._lang,
    course_name: course?.title,
  });
};

const getPosthogEventNameByType = (event: NavigationTrackingEventName) => {
  switch (event) {
    case NavigationTrackingEventName.CLICK_ON_CLOSE_COURSE_PREVIEW:
      return 'CoursePreview Closed';
    case NavigationTrackingEventName.CLICK_ON_COURSE_PREVIEW:
      return 'CoursePreview Opened';
    case NavigationTrackingEventName.CLICK_ON_OPEN_COURSE:
      return 'Course Opened';
    default:
      return '';
  }
};

class LaerdalTracking {
  trackingProviders: TrackingProvider[];
  googleAnalyticsEnabled: boolean;
  appcuesEnabled: boolean;

  constructor(
    isGoogleAnalyticsEnabled: boolean | undefined,
    isAppcuesEnabled: boolean | undefined,
    providers: TrackingProvider[] = [TrackingProvider.GoogleAnalytics, TrackingProvider.AppCues],
  ) {
    this.trackingProviders = providers;
    this.appcuesEnabled = isAppcuesEnabled === true;
    this.googleAnalyticsEnabled = isGoogleAnalyticsEnabled === true;
  }

  track(event: TrackingEvent | undefined): void {
    if (!event) return;
    // do not track if an active signin on behalf session exists
    if (SignInOnBehalfService.isActive()) return;
    for (let provider of this.trackingProviders) {
      if (
        provider === TrackingProvider.AppCues &&
        this.appcuesEnabled &&
        Object.values(AppCuesFlowEventName).includes(event.name as AppCuesFlowEventName)
      ) {
        if (window.Appcues) {
          window.Appcues.track(event.name, event.data);
        }
      } else if (
        provider === TrackingProvider.GoogleAnalytics &&
        this.googleAnalyticsEnabled &&
        Object.values(NavigationTrackingEventName).includes(event.name as NavigationTrackingEventName)
      ) {
        // @ts-ignore
        if (window.gtag) {
          // @ts-ignore
          window.gtag('event', event.name, event.data);
        }
      }
    }
  }

  generateTrackingData(
    eventType: TrackingEventType,
    eventName: TrackingEventName,
    data: TrackingEventData,
  ): TrackingEvent {
    let event: TrackingEvent = {
      name: eventName,
      data: {
        ...data,
        eventCategory: eventType,
        sourcePath: window.location.pathname,
        domain: window.location.hostname,
      },
    };
    return event;
  }
}

export default LaerdalTracking;
