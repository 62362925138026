import axios, { CancelTokenSource } from 'axios';
import { AuthApi } from '.';
import { BannerType, User, UserBanner } from '../types';
import { UserPreferences } from 'typescript';

class UserApi {
  GetCurrentUserInfo = async (): Promise<User> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/user/current`, {
        headers: { Authorization: `Bearer ${await AuthApi.getToken()}` },
      })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  SetCurrentUserOrganization = async (customerNumber: string): Promise<void> => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/user/current-organization/${customerNumber}`, null, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    });
  };

  SetCurrentUserPreferences = async (preferences: UserPreferences): Promise<boolean> => {
    return axios
      .put(`${process.env.REACT_APP_API_URL}/user/preferences`, preferences, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        return response?.status === 200;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  GetCurrentUserBanners = async (
    type: BannerType,
    includeSoftDelete: boolean = false,
    source: CancelTokenSource | null = null,
  ): Promise<UserBanner[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/banners?type=${type}&includeSoftDelete=${includeSoftDelete}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
        cancelToken: source?.token,
      })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  RemoveUserBanners = async (id: string, isSoftDelete: boolean = false): Promise<void> => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/banners/${id}?isSoftDelete=${isSoftDelete}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        return;
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  AddBanner = async (type: number): Promise<UserBanner> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/banners/${type}`, null, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };
}

export default new UserApi();
