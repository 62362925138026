import { BREAKPOINTS, COLORS, ComponentSStyling, ComponentTextStyle } from '@laerdal/life-react-components';
import styled from 'styled-components';

export const NoOrgDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${COLORS.neutral_100};
  border-radius: 8px;
  padding: 24px;
  .noOrgImg {
    height: auto;
    max-width: 320px;
    height: 180px;
  }
  margin-bottom: 16px;
  ${BREAKPOINTS.MEDIUM} {
    margin-bottom: 32px;
  }
`;

export const NoOrgDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  ${BREAKPOINTS.MEDIUM} {
    padding: 28px;
  }
`;

export const LeftColumnDiv = styled.div`
  display: none;
  ${BREAKPOINTS.MEDIUM} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 28px;
    width: 50%;
  }
`;

export const RightColumnDiv = styled.div`
  width: 100%;
  ${BREAKPOINTS.MEDIUM} {
    width: 50%;
  }
`;

export const Heading = styled.h4``;

export const StepsContainer = styled.div`
  margin: 24px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

export const StepCircle = styled.div`
  height: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background-color: ${COLORS.primary_500};
  border-radius: 18px;
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.white)}
`;

export const StepRow = styled.p`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

export const ActionsDiv = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  align-items: flex-start;
  flex-direction: column;
  button {
    width: 100%;
  }

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
    button {
      width: auto;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InformationImage = styled.img`
  height: 275px;
  max-width: 100%;
`;
