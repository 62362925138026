import { useEffect } from 'react';
import { GlobalLoadingPage } from '@laerdal/life-react-components';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { AppName } from '../../constants';

const StyledMain = styled.main`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const Login = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const action = search.get('gig_actions');
    switch (action) {
      case 'sso.login':
        !search.get('returnUrl') && navigate('/');
        search.get('returnUrl') && navigate(search.get('returnUrl')!);
        break;
      default:
        //@ts-ignore
        window.gigya.sso.login({
          authFlow: 'redirect',
          redirectURL: window.location.href,
          useChildContext: true,
          context: {
            application: AppName,
            allowRegistration: true,
          },
        });
    }
  }, []);

  return (
    <>
      <StyledMain id="main">
        <GlobalLoadingPage />
      </StyledMain>
    </>
  );
};

export default Login;
