import {
  Banner,
  BREAKPOINTS,
  LoadingIndicator,
  PageWidth,
  Size,
  SystemIcons,
  Toast,
  ToastActionType,
  ToastColor,
  ToastContext,
  ToastPosition,
} from '@laerdal/life-react-components';
import { useContext, useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectOrganization } from '../../store/slice/organization';
import { BannerContainer, CourseSectionContainer, LoadingContainer, PaddingsDiv } from './styles';
import { CourseDetails, CourseVisibilitySetting, EclCourseSanity } from '../../types';
import { CourseDetailsModal } from './modals/CourseDetailsModal';
import { selectLanguages } from '../../store/slice/settings';
import {
  fetchCourseCodes,
  fetchSanityCourseLabels,
  fetchSanityCourses,
  selectInitialized,
  selectCourseCodes,
  selectSanityCourses,
  fetchCourseTracking,
  selectFilteredCourses,
  setLocaleComputedCourses,
  setSortCriteria,
  selectSortCriteria,
  fetchSimulators,
  selectFilterCriteria,
  setFilterCriteria,
  resetTrackingData,
  selectAllCourses,
  fetchCourseMappingExtraSettings,
  selectCoursesExtraVisibilitySettings,
} from '../../store/slice/courses';
import { AppDispatch, useAppDispatch } from '../../store';
import { NavigationTrackingEventName, trackCourseData } from '../../helpers/tracking';
import { NoCourses } from './NoCourses';
import { PageHeader } from './components/PageHeader';
import { FilterSortOptions, SortOptions } from './components/FilterAndSortOptions';
import { LibrarySection } from './components/LibrarySection';
import { CourseFilterCriteria } from '../../store/slice/courses/types';
import { LearnAboutSubscription } from './components/LearnAboutSubscription';
import { useCourseDataHelpers } from './hooks/CourseDataHelper';
import { AppName } from '../../constants';
import { useLocation } from 'react-router';
import { selectUserContentLanguage, selectUserId } from '../../store/slice/user';
import { useMediaMatch } from 'rooks';
import { getToastConfig, ToastType } from '../../helpers/utils';
import { useCourseLabels } from './hooks/CourseLabelsHook';
import { ToastOptions } from '@laerdal/life-react-components/dist/Toasters/Toast';
import { FreeTrialExperiment } from './components/FreeTrialExperiment';

export enum CourseSectionType {
  NewToKnowledgeHub = 0,
  MostRelevant = 1,
  Subscription = 2,
  // will be used later
  LearnAgain = 3,
  Free = 4,
}

export type CourseSectionTypeValue = keyof typeof CourseSectionType;

const EclCoursesPage = () => {
  const { t } = useTranslation('Ecl');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<EclCourseSanity>();

  const organization = useSelector(selectOrganization);

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const languages = useSelector(selectLanguages);

  const coursesLoaded = useSelector(selectInitialized);
  const eclcourses = useSelector(selectCourseCodes);
  const sanityEclCourses = useSelector(selectSanityCourses);
  const coursesExtraVisibilitySettings = useSelector(selectCoursesExtraVisibilitySettings);
  const sortCriteria = useSelector(selectSortCriteria);
  const filterCriteria = useSelector(selectFilterCriteria);
  const userId = useSelector(selectUserId);
  const contentLanguage = useSelector(selectUserContentLanguage);

  const [loadingState, setLoadingState] = useState(true);

  const dispatch = useAppDispatch() as AppDispatch;

  const allCourses = useSelector(selectAllCourses);
  const sortedCourses = useSelector(selectFilteredCourses);
  const { isEclOrganization, computeCoursesByOrgLocale, hasActiveECLSubscription, isCourseSupportedInUserLocale } =
    useCourseDataHelpers();
  const { getAllCourseLabelsByLocale } = useCourseLabels();

  const location = useLocation();
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    if (!sortCriteria) {
      dispatch(setSortCriteria(isEclOrganization ? SortOptions.TitleAscending : SortOptions.Relevance));
    }
  }, [sortCriteria, isEclOrganization]);

  const coursesVisibilityGroupping = useMemo(() => {
    return sortedCourses
      ? sortedCourses.reduce((accumulator: { [x: string]: CourseDetails[] }, currentValue) => {
          let sectionType: keyof typeof CourseSectionType | undefined;

          if (currentValue.recentlyAdded) {
            sectionType = CourseSectionType[CourseSectionType.NewToKnowledgeHub] as CourseSectionTypeValue;
          } else if (currentValue.visibilitySettings !== CourseVisibilitySetting.Free) {
            if (hasActiveECLSubscription() && !currentValue.ownsRelatedProducts) {
              sectionType = CourseSectionType[CourseSectionType.Subscription] as CourseSectionTypeValue;
            } else if (currentValue.ownsRelatedProducts) {
              sectionType = CourseSectionType[CourseSectionType.MostRelevant] as CourseSectionTypeValue;
            }
          } else {
            sectionType = CourseSectionType[CourseSectionType.Free] as CourseSectionTypeValue;
          }
          if (!!sectionType) {
            if (!accumulator[sectionType]) {
              accumulator[sectionType] = [];
            }
            accumulator[sectionType].push(currentValue);
          }
          return accumulator;
        }, {})
      : {};
  }, [sortedCourses]);

  useEffect(() => {
    dispatch(fetchCourseCodes());
    dispatch(fetchSanityCourseLabels());
    dispatch(fetchSimulators());
    dispatch(fetchSanityCourses());
    dispatch(fetchCourseTracking());
    //for now, only free course have additional visibility settings
    dispatch(fetchCourseMappingExtraSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      dispatch(resetTrackingData());
    };
  }, []);

  useEffect(() => {
    setLoadingState(!(organization && coursesLoaded && (languages?.length ?? 0) > 0));
  }, [organization, coursesLoaded, languages]);

  useEffect(() => {
    const expiredCourseId = sessionStorage.getItem('courseExpired');
    if (!loadingState && expiredCourseId?.length && !!sanityEclCourses?.length) {
      const eclCourse = sanityEclCourses.find((c) => c._id === expiredCourseId);
      addToast(
        t('Your access to the course {{courseName}} has expired.', { courseName: eclCourse ? eclCourse.title : '' }),
        getToastConfig(ToastType.Info, <SystemIcons.Information />),
      );
      sessionStorage.removeItem('courseExpired');
    }
    const versionedCourseNotFound = sessionStorage.getItem('courseVersionNotFound');
    if (!loadingState && versionedCourseNotFound?.length && !!sanityEclCourses?.length) {
      const notFoundVersionedCourse = sanityEclCourses.find((c) => c._id === versionedCourseNotFound);
      addToast(
        t('Course {{courseName}} not found.', {
          courseName: notFoundVersionedCourse ? notFoundVersionedCourse.title : '',
        }),
        getToastConfig(ToastType.Info, <SystemIcons.Information />),
      );
      sessionStorage.removeItem('courseVersionNotFound');
    }
  }, [location, sanityEclCourses, loadingState]);

  // filter out the ones that are not in default languages. fallback to en.
  useEffect(() => {
    if (coursesLoaded) {
      const list = computeCoursesByOrgLocale(eclcourses, sanityEclCourses);
      let noEngCountries = process.env.REACT_APP_ECL_NO_ENGLISH_COURSE_COUNTRIES?.split(',');

      if (
        !showBanner &&
        list.some(
          (c) =>
            !isCourseSupportedInUserLocale(c._lang) &&
            !noEngCountries?.includes(organization?.address.country.codeAlpha2 ?? '') &&
            !contentLanguage?.toLowerCase()?.includes('en'),
        )
      ) {
        setShowBanner(true);
      }
      dispatch(setLocaleComputedCourses(list));
    }
  }, [sanityEclCourses, eclcourses, coursesLoaded, organization, contentLanguage]);

  const closeModal = () => {
    trackCourseData(NavigationTrackingEventName.CLICK_ON_CLOSE_COURSE_PREVIEW, selectedCourse);
    setShowModal(false);
  };

  const openModal = (course: EclCourseSanity) => {
    setSelectedCourse(course);
    setShowModal(true);
    return () => {};
  };

  const getTitleBySectionType = (section: CourseSectionTypeValue) => {
    switch (section) {
      case CourseSectionType[CourseSectionType.NewToKnowledgeHub]:
        return t('Recently added');
      case CourseSectionType[CourseSectionType.MostRelevant]:
        return t('Most relevant for you');
      case CourseSectionType[CourseSectionType.Subscription]:
        return t('Other courses on your subscription');
      case CourseSectionType[CourseSectionType.Free]:
        return t('Free content for you');
      default:
        return '';
    }
  };

  return (
    <>
      <Helmet>
        <title>{AppName}</title>
      </Helmet>
      <main id="main" style={{ height: '100vh', display: 'table' }}>
        {showBanner && (
          <BannerContainer>
            <Banner
              icon={<SystemIcons.Information />}
              size={isLargeScreen ? Size.Large : isMediumScreen ? Size.Medium : Size.Small}>
              {t(
                'At present some of our content is not yet available in your selected language. It is displayed in "English".',
              )}
            </Banner>
          </BannerContainer>
        )}
        <PageWidth useMaxWidth={true} maxWidth={1600}>
          <PaddingsDiv>
            <FreeTrialExperiment toastPrerequisitesMet={!hasActiveECLSubscription()} />
            <PageHeader
              showShareBtn={coursesLoaded && !!sortedCourses?.length}
              title={AppName}
              subtitle={t('Learn the basics of simulation and how to use your equipment efficiently.')}
            />
            {loadingState && !sortedCourses?.length && (
              <LoadingContainer>
                <LoadingIndicator></LoadingIndicator>
              </LoadingContainer>
            )}
            {!loadingState && !allCourses?.length && <NoCourses></NoCourses>}

            <CourseSectionContainer>
              {!loadingState && !!allCourses && allCourses.length > 0 && (
                <>
                  <FilterSortOptions
                    isEclOrganization={isEclOrganization}
                    onFilterValueChanged={(value: Partial<CourseFilterCriteria>) => {
                      dispatch(setFilterCriteria({ ...filterCriteria, ...value }));
                    }}
                    getAllCourseLabelsByLocale={getAllCourseLabelsByLocale}
                    availableCourseLabelFilters={allCourses!.filter((course) => !!course.label).map((c) => c.label)}
                    availableSimulatorFilters={allCourses!
                      .filter((course) => !!course.simulator)
                      .map((c) => c.simulator)}
                    filterCriteria={filterCriteria}
                    onSortValueChanged={(sortOption) => dispatch(setSortCriteria(sortOption))}
                    selectedSortValue={sortCriteria!}
                  />
                </>
              )}
              {!loadingState && !!sortedCourses && sortedCourses.length > 0 && (
                <>
                  {Object.keys(coursesVisibilityGroupping)
                    .sort((a: string, b: string) => {
                      const index1 = Object.values(CourseSectionType).indexOf(a);
                      const index2 = Object.values(CourseSectionType).indexOf(b);
                      return index1 - index2;
                    })
                    .filter((key) => key != CourseVisibilitySetting[CourseVisibilitySetting.Free])
                    .map((sectionKey) => {
                      return (
                        <LibrarySection
                          section={sectionKey as CourseSectionTypeValue}
                          courses={coursesVisibilityGroupping[sectionKey]}
                          languages={languages}
                          isEclOrg={isEclOrganization}
                          onOpenModal={openModal}
                          activeSubscription={hasActiveECLSubscription()}
                          title={getTitleBySectionType(sectionKey as CourseSectionTypeValue)}
                          key={sectionKey}
                        />
                      );
                    })}
                </>
              )}
              {!loadingState &&
                eclcourses &&
                eclcourses.length > 0 &&
                coursesVisibilityGroupping[CourseSectionType[CourseSectionType.Free]]?.length > 0 && (
                  <LibrarySection
                    isEclOrg={isEclOrganization}
                    section={CourseSectionType[CourseSectionType.Free] as CourseSectionTypeValue}
                    courses={coursesVisibilityGroupping[CourseSectionType[CourseSectionType.Free]]}
                    languages={languages}
                    onOpenModal={openModal}
                    title={
                      isEclOrganization
                        ? undefined
                        : getTitleBySectionType(CourseSectionType[CourseSectionType.Free] as CourseSectionTypeValue)
                    }
                    key={CourseSectionType[CourseSectionType.Free]}
                  />
                )}
              {!loadingState && !hasActiveECLSubscription() && !isEclOrganization && <LearnAboutSubscription />}
            </CourseSectionContainer>
          </PaddingsDiv>
        </PageWidth>
      </main>

      <CourseDetailsModal
        closeModal={closeModal}
        courseNotInOrgLanguage={
          !!organization &&
          !!selectedCourse &&
          !isCourseSupportedInUserLocale(selectedCourse._lang) &&
          !contentLanguage?.includes('en')
        }
        customerCountryIso={organization?.address?.country?.codeAlpha2}
        userId={userId}
        customerNumber={organization?.customerNo}
        showModal={showModal}
        selectedCourse={selectedCourse}
      />
    </>
  );
};

export default EclCoursesPage;
