import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectOrganization } from '../../../store/slice/organization';
import { Course, CourseDetails, CourseVisibilitySetting, EclCourseSanity } from '../../../types';
import { selectUserContentLanguage } from '../../../store/slice/user';

export const useCourseDataHelpers = () => {
  const organization = useSelector(selectOrganization);
  const contentLocale = useSelector(selectUserContentLanguage);

  const contentLanguage = useMemo(() => {
    const localeArray = contentLocale?.split('-');
    return localeArray && localeArray?.length > 0 ? localeArray[0] : contentLocale;
  }, [contentLocale]);

  const isCourseSupportedInUserLocale = (courseLanguage: string) => {
    const languagesWithMultipleRegions = process.env.REACT_APP_COURSES_LANGUAGE_MULTIPLE_REGIONS?.split(',') ?? [];
    //match course by exact locale or if the selected locale match at least the course language
    //languages with multiple regions support need to have a locale match
    return (
      courseLanguage?.toLowerCase() === contentLocale?.toLowerCase() ||
      (!languagesWithMultipleRegions.some((locale) => contentLocale === locale) &&
        contentLanguage &&
        courseLanguage.includes(contentLanguage))
    );
  };

  const computeCoursesByOrgLocale = useCallback(
    (availableCourses: Course[] | undefined, sanityEclCourses: EclCourseSanity[] | undefined) => {
      let courseMap: { [id: string]: EclCourseSanity[] } = {};
      let noEngCountries = process.env.REACT_APP_ECL_NO_ENGLISH_COURSE_COUNTRIES?.split(',');
      const coursesWithoutDrafts = sanityEclCourses?.filter((c: EclCourseSanity) => !c?._id.includes('drafts'));
      coursesWithoutDrafts!.forEach((c) => {
        if (!Object.keys(courseMap).includes(c.code)) {
          courseMap[c.code] = [] as EclCourseSanity[];
        }
        courseMap[c.code].push(c);
      });
      let list: CourseDetails[] = [];

      if (isEclOrganization) {
        list = (coursesWithoutDrafts ?? []).map((c) => {
          return { ...c, visibilitySettings: CourseVisibilitySetting.Free };
        }) as CourseDetails[];
      } else {
        list = availableCourses!
          .map((p) => {
            let course = courseMap[p.courseCode]?.find((c) => isCourseSupportedInUserLocale(c._lang)) as CourseDetails;
            if (!course && !noEngCountries?.includes(organization?.address.country.codeAlpha2 ?? '')) {
              //fallback language - en
              course = courseMap[p.courseCode]?.find((c) => c._lang === 'en') as CourseDetails;
            }
            return {
              ...course,
              expireAt: p.expireAt,
              visibilitySettings: p.visibilitySettings,
              ownsRelatedProducts: p.ownsRelatedProducts,
            };
          })
          .filter((data) => !!data._id);
      }

      return list;
    },
    [contentLocale, organization],
  );

  const hasActiveECLSubscription = useCallback(() => {
    const eclServiceSubscription = organization?.services.find(
      (s) => s.serviceId?.toLowerCase() === process.env.REACT_APP_ECL_SERVICE_ID?.toLowerCase(),
    )?.subscription;
    return eclServiceSubscription?.isActive ?? false;
  }, [organization]);

  const isEclOrganization = useMemo(() => {
    return organization?.id === process.env.REACT_APP_ECL_ORGANIZATION_ID;
  }, [organization?.id]);

  return { isEclOrganization, computeCoursesByOrgLocale, hasActiveECLSubscription, isCourseSupportedInUserLocale };
};
