import { selectHasSignedInOnBehalf, setHasSignedInOnBehalf } from '../store/slice/user';
import { useEffect } from 'react';
import { useAppDispatch } from '../store';
import { useSelector } from 'react-redux';
import SignInOnBehalfService from '@laerdal/sign-in-on-behalf';

export const useSignInOnBehalf = (): boolean => {
  const hasSignedInOnBehalf = useSelector(selectHasSignedInOnBehalf);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const updateSignInOnBehalf = (value: boolean) => {
      dispatch(setHasSignedInOnBehalf(value));
    };
    SignInOnBehalfService.on('changed', updateSignInOnBehalf);

    return () => {
      SignInOnBehalfService.off('changed', updateSignInOnBehalf);
    };
  }, []);

  return hasSignedInOnBehalf || false;
};
