import LaerdalTracking from '../helpers/tracking';

export interface Feature {
  featureName: string;
  isEnabled: boolean;
}

export interface Language {
  name: string;
  locale: string;
}

export interface Jwt {
  apiKey: string;
  iss: string;
  iat: number;
  exp: number;
  sub: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface User {
  id: string;
  email: string;
  identityId: string;
  firstName: string;
  lastName: string;
  portalAccess?: boolean;
  hasEligibleCountry?: boolean;
  hasOrganizationInvitations?: boolean;
  currentOrganization: Organization | null;
  organizations: Organization[];
  services: Service[];
  isProfilingComplete?: boolean;
  companyRoles?: CompanyRole[];
  connectLightProductsSetupComplete?: boolean;
  country?: string;
  userPreferences?: UserPreferences;
  switchOrganizationEnabled?: boolean;
}

export interface UserPreferences {
  contentLanguage: string;
}

export interface ISortable {
  order: number;
}

export interface EclCourseSanity extends ISortable {
  _id: string;
  _lang: string;
  authors: string;
  code: string;
  description: string;
  durationFrom?: number;
  durationTo?: number;
  enabled: boolean;
  image: { asset: { _ref: string } };
  title: string;
  label: string;
  simulator: string;
  versionList: EclSanityCourseVersion[];
  extraVisibilitySettings?: CourseExtraVisibilitySetting;
}

export interface EclSanityCourseVersion {
  versionNumber: string;
  courseUrl: string;
  isDefault?: boolean;
}

export interface CourseDetails extends EclCourseSanity {
  recentlyAdded: boolean;
  expireAt?: Date;
  ownsRelatedProducts?: boolean;
  visibilitySettings?: CourseVisibilitySetting;
}

export enum CourseExtraVisibilitySetting {
  FreeForLimitedTime,
}

export enum CourseVisibilitySetting {
  Free,
  OneYearLimitedAccess,
  UnlimitedAccess,
}

export interface CourseDocumentLabelSanity extends CourseOptionsDocumentSanity {
  _id: string;
  _lang: string;
}

export interface CourseOptionsDocumentSanity {
  items: OptionSanity[];
}

export interface MappingExtraSettingSanity {
  courseCode: string;
  visibilitySettings: string;
  freeForLimitedTime?: boolean;
  releaseDate?: string;
}

export interface OptionSanity {
  labelText: string;
  labelValue: string;
}

export enum InstanceLimitType {
  User,
  Session,
  Role,
}

export interface Service {
  id: string;
  serviceId?: string;
  name: string;
  serviceUrl: string;
  iconUrl?: string;
  alwaysListInSwitcher?: boolean;
  switcherDisplayName?: string;
  serviceRoles?: ServiceRole[];
  members?: OrganizationServiceMember[];
  availablePlans?: ServicePlan[];
  subscriptionManagementPath?: string;
  instanceLimitType: InstanceLimitType;
  commerceMarket: Market;
  subscription: Subscription;
}

export interface Subscription {
  id: string;
  isActive: boolean;
  expirationDate?: Date;
}

export interface Market {
  id: string;
  code: string;
  organizationCode: string;
}

export interface ServicePlan {
  id: string;
  name: string;
  tiers: ServicePlanTier[];
  allowTrial: boolean;
  defaultTrialDuration: number;
  defaultTrialInstances?: number;
  subBrandName: string;
  partNumber: string;
  upgradeOrder?: number;
}

export interface ServicePlanTier {
  id: string;
  name: string;
  allowTrial: boolean;
  defaultTrialDuration: number;
  defaultTrialInstances?: number;
  maxSubscriptionInstances: number;
  individualLicensing?: boolean;
  prices: ServicePlanTierPrice[];
  upgradeOrder?: number;
}

export interface ServicePlanTierPrice {
  id: string;
  currency: string;
  basePrice: number;
  unitPrice: number;
  country: string;
}

export interface OrganizationServiceMember {
  id?: string;
  created?: Date;
  user: User;
  role?: ServiceRole;
}

export interface ServiceRole {
  id: string;
  name: string;
  description: string;
  parentRoleId?: string;
  parentRoleName?: string;
  parentRoleDescription?: string;
}

export interface Organization {
  id: string;
  parent: Organization;
  customerNo: string;
  name: string;
  logoUrl?: string;
  industry: Industry;
  address: Address;
  defaultLanguage: string;
  billingOrganizationName: string;
  vatNumber: string;
  isOnboarded: boolean;
  billingContactPerson: string;
  billingEmailAddress: string;
  useOrganizationAddressForBilling: boolean;
  organizationCode: string;
  billingAddress: Address;
  members: OrganizationMember[];
  invitations: Invitation[];
  services: Service[];
}

export enum Industry {
  Healthcare,
  EducationalProvider,
  EmergencyMedicalService,
  GovFederalAndMilitary,
  Other,
}

export interface Address {
  addressLine: string;
  zipCode: string;
  city: string;
  state: string;
  country: Country;
}

export interface OrganizationMember {
  id: string;
  organizationId: string;
  organization: string;
  role: OrganizationRole | null | undefined;
  user: User;
  current: boolean;
  companyRoles: CompanyRole[];
  invitation: { code: string; expired: boolean };
  created?: Date;
  permissions: { permissionId: string }[];
}

export interface CompanyRole {
  id: string;
  name: string;
  isPredefined: boolean;
  selected: boolean;
}

export interface OrganizationRole {
  id: string;
  name: string;
}

export interface Invitation {
  id: string;
  email: string;
  organization: Organization;
  inviter: string;
  createdDate: string;
  createdBy: string;
  acceptedDate: string;
  isActive: boolean;
  role: ServiceRole;
  code: string;
  firstName: string;
  lastName: string;
}

export interface Country {
  id: string;
  name: string;
  codeAlpha2: string;
  codeAlpha3: string;
  locale: string;
}

export interface CompanyRole {
  id: string;
  name: string;
  isPredefined: boolean;
  selected: boolean;
}

export enum BannerType {
  NewSubscription = 1,
  ViewedNonEnglishCourse,
  FreeTrial,
}

export interface UserBanner {
  id: string;
  identityId: string;
  bannerTye: BannerType;
  data: any;
  isDeleted: boolean;
}

export interface SigninOnBehalfCookie {
  gigyaCookieValue: string;
  expires: Date;
}

export interface SigninOnBehalfValidationResult {
  cookie: SigninOnBehalfCookie | undefined;
  isSessionActive: boolean;
  isLogoutRequired: boolean;
  details: string;
}

export interface Country {
  id: string;
  name: string;
  codeAlpha2: string;
  codeAlpha3: string;
  locale: string;
}

export interface FeatureRuleDto {
  featureName: string;
  //serviceId
  serviceIdentifier?: string;
  //countryCode
  countryIdentifier?: string;
  //organizationId or customerNumber
  organizationIdentifier?: string;
  //userId, identityId or email
  userIdentifier?: string;
  //serial number
  assetIdentifier?: string;
}

export interface OrgServiceResponseDto {
  email: string;
  isSuccessful: boolean;
}

// Leaving this empty in case we need to check feature flags from Connect in the future
export enum AdvancedFeatureFlags {}

export interface CourseTracking {
  userId: string;
  organizationId: string;
  courseCode: string;
  firstAccessed: number;
  lastAccessed: number;
  numberOfAccesses: number;
}

export interface Course {
  courseCode: string;
  expireAt: Date;
  visibilitySettings: CourseVisibilitySetting;
  ownsRelatedProducts: boolean;
}

declare global {
  interface Window {
    LaerdalTracking: LaerdalTracking | null;
  }
}
