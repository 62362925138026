import { BREAKPOINTS, Button, COLORS, Size, SystemIcons } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import subscription_banner from '../../../assets/subscription_banner.svg';
import { NavigationTrackingEventName, TrackingEventType } from '../../../helpers/tracking';
import { selectUserId } from '../../../store/slice/user';
import { ImageContainer } from '../../NoOrganization/styles';
import { selectOrganization } from '../../../store/slice/organization';
import { useCallback } from 'react';
import posthog from 'posthog-js';

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 32px 16px 32px 16px;
  ${BREAKPOINTS.SMALL} {
    flex-direction: column;
  }
  ${BREAKPOINTS.MEDIUM} {
    flex: 1;
    padding: 16px;
    flex-direction: row;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fdf0de; //this will be replaced with accent_50
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 480px;
  button {
    width: 100%;
  }
  ${BREAKPOINTS.SMALL} {
    align-items: center;
    text-align: center;
  }
  ${BREAKPOINTS.MEDIUM} {
    align-items: flex-start;
    text-align: left;
  }

  padding: 0px 16px;
  gap: 16px;
`;

const TextHeading = styled.h6``;

export const InformationImage = styled.img`
  height: 275px;
  max-width: 100%;
`;

export const LearnAboutSubscription = () => {
  const { t } = useTranslation('Ecl');
  const laerdalTracking = window.LaerdalTracking;
  const userId = useSelector(selectUserId);
  const organization = useSelector(selectOrganization);

  const onLearnMoreBtnClick = useCallback(() => {
    let trackingEvent = laerdalTracking?.generateTrackingData(
      TrackingEventType.Navigation,
      NavigationTrackingEventName.SUBSCRIPTION_LEARN_MORE,
      { userID: userId, countryId: organization?.address?.country?.codeAlpha2 },
    );
    laerdalTracking?.track(trackingEvent);
    posthog.capture(`KH SubscriptionLearnMoreButton Clicked`);
    window.open('https://laerdal.com/services/knowledgehub', '_blank');
  }, [userId, organization]);

  return (
    <MainContainer>
      <BannerContainer>
        <ImageContainer>
          <InformationImage className="noOrgImg" src={subscription_banner}></InformationImage>
        </ImageContainer>
        <TextContainer>
          <TextHeading>{t('Learn more with a subscription')}</TextHeading>
          <p>
            {t(
              'Bring your organization to a whole new level with our full library of knowledge into simulation and expertise in Laerdal products.',
            )}
          </p>
          <Button size={Size.Medium} icon={<SystemIcons.OpenNewWindow />} onClick={onLearnMoreBtnClick}>
            {t('Learn more')}
          </Button>
        </TextContainer>
      </BannerContainer>
    </MainContainer>
  );
};
