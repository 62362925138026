import i18next from 'i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { switchLocale } from '../day';
import i18n from '../i18n/config';
import { selectLocale } from '../store/slice/user';

export const useLocalization = () => {
  const appLocale = useSelector(selectLocale);

  useEffect(() => {
    i18next.changeLanguage(appLocale || process.env.REACT_APP_DEFAULT_LANGUAGE);
  }, [appLocale]);

  useEffect(() => {
    const locale = appLocale?.toLowerCase();
    const defaultLocale = process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en';
    switchLocale(locale, defaultLocale);
  }, [appLocale]);

  useEffect(() => {
    i18n.on('languageChanged', (lang) => {
      document.documentElement.lang = lang;

      const root = document.getElementById('root');
      if (root) {
        root.lang = lang;
      }

      window?.globalNav?.setLocale(lang);
    });
  }, [i18n]);
};
