import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import i18n from './i18n/config';

dayjs.extend(localeData);
//list all plugins here
dayjs.extend(duration);

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(updateLocale);

const localeList = dayjs.Ls;

export const switchLocale = (locale: string | undefined, defaultLocale: string) => {
  if (!locale) {
    dayjs.locale(defaultLocale);
    updateLocaleSettings(defaultLocale);
    return;
  }

  const localeVariantArray = locale.split('-');
  //in case there are multiple variants for the some country and none is matched,
  //we should try using the base country code (ex: nb-no, use nb as a fallback)
  const fallbackBaseLang = localeVariantArray[0];

  //first try getting the format based on country locale
  setLocale(locale.toLowerCase()).catch(() => {
    //if country locale fails, try based on country code
    setLocale(fallbackBaseLang.toLowerCase()).catch(() => {
      //falback to default
      dayjs.locale(defaultLocale);
      updateLocaleSettings(defaultLocale);
    });
  });
};

const setLocale = (locale: string) => {
  return import(`dayjs/locale/${locale}.js`).then(() => {
    dayjs.locale(locale);
    updateLocaleSettings(locale);
  });
};

const updateLocaleSettings = (locale: string) => {
  dayjs.updateLocale(locale, {
    relativeTime: {
      ...localeList[locale].relativeTime,
      h: i18n.t('1 hour', { ns: 'Common' }),
    },
  });
};
