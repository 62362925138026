import React from 'react';
import { useSelector } from 'react-redux';
import { Banner } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { logOut, selectEmail, selectHasSignedInOnBehalf } from '../../store/slice/user';
import { useSignInOnBehalf } from '../../helpers/signInOnBehalf-hoc';
import { AppDispatch, useAppDispatch } from '../../store';

export const SingedInOnBehalfBanner: React.FunctionComponent = () => {
  const { t } = useTranslation('Common');
  const dispatch = useAppDispatch() as AppDispatch;

  const email = useSelector(selectEmail);

  const hasSignedInOnBehalf = useSelector(selectHasSignedInOnBehalf);

  if (!hasSignedInOnBehalf) return null;

  return (
    <Banner type="critical" link="#" linkAction={() => dispatch(logOut())} linkText={t('End session')}>
      {t('You are currently signed in on behalf of {{email}}.', { email: email })}{' '}
    </Banner>
  );
};
