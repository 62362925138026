import { BREAKPOINTS, Banner, Size, SystemIcons } from '@laerdal/life-react-components';
import { ExcludeFooterRoutes } from '../../constants';
import { BannerContainer } from '../../pages/Ecl/styles';
import { Footer } from '../Footer/Footer';
import Header from '../Header/Header';
import { useIsCurrentRoutePath } from './CurrentPathHook';
import { SingedInOnBehalfBanner } from './SignInOnBehalfBanner';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaMatch } from 'rooks';
import { isFeatureActivated, selectFeatures } from '../../store/slice/features';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const shouldExcludeFooter = useIsCurrentRoutePath(ExcludeFooterRoutes);
  const isLargeScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const { t } = useTranslation('Common');
  const features = useSelector(selectFeatures);

  return (
    <>
      <Header />
      <SingedInOnBehalfBanner />
      {isFeatureActivated('SHOW_UNDER_MAINTENANCE', features) && (
        <BannerContainer>
          <Banner
            type={'warning'}
            icon={<SystemIcons.TechnicalWarning />}
            size={isLargeScreen ? Size.Large : isMediumScreen ? Size.Medium : Size.Small}>
            {t(
              'We are busy updating KnowledgeHub for you. We apologize if the full KnowledgeHub experience is unavailable at the moment',
            )}
          </Banner>
        </BannerContainer>
      )}
      <>{children}</>
      {!shouldExcludeFooter && <Footer />}
    </>
  );
};
