import React from 'react';
import styled from 'styled-components';
import { GlobalLoadingPage } from '@laerdal/life-react-components';
const LoadingWrapper = styled.div`
  & main {
    min-height: calc(100vh - 80px);
  }
`;

export const GlobalLoader = () => {
  return (
    <LoadingWrapper>
      <GlobalLoadingPage />
    </LoadingWrapper>
  );
};
