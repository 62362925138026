import { Toast, ToastColor, ToastPosition } from '@laerdal/life-react-components';
import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { EclCourseSanity, Language } from '../types';
import { ToastOptions } from '@laerdal/life-react-components/dist/Toasters/Toast';

export enum ToastType {
  Success,
  Info,
  Error,
}

export const capitalizeFirstLetter = (text: string | null | undefined) => {
  if (text) {
    return text?.charAt(0).toUpperCase() + text?.slice(1);
  } else {
    return '';
  }
};

export const getLanguageByCode = (languages: Language[] | undefined, code: string) => {
  const lang = languages?.find((l) => l.locale?.toLowerCase() === code?.toLowerCase());
  return lang?.name;
};

export const getToastConfig = (type: ToastType, icon: React.ReactNode = null) => {
  return {
    color: ToastType.Info ? ToastColor.BLUE : type === ToastType.Success ? ToastColor.GREEN : ToastColor.RED,
    showCloseButton: true,
    autoClose: true,
    position: ToastPosition.TOPMIDDLE,
    icon,
  } as ToastOptions;
};

/**
 *
 * @param list
 * @param keyGetter
 * @returns a map groupping with the specified key
 */
export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

// comparator function for any property within type T
// works for: strings, numbers, and Date
// could be extended for other types but would need some custom comparison function here
export function compareProp<T extends PropertyKey>(property: T, isDescending: boolean = false) {
  return (objectA: { [k in T]: string | number | Date }, objectB: { [k in T]: string | number | Date }) => {
    if (objectA[property] > objectB[property]) {
      return isDescending ? -1 : 1;
    } else if (objectA[property] < objectB[property]) {
      return isDescending ? 1 : -1;
    } else {
      return 0;
    }
  };
}

export const getMinDateValue = () => new Date('1970-01-01').valueOf();

/**
 * @param  {EclCourseSanity|undefined} course
 *
 * If course is not undefined, format durationFrom first if not undefined.
 * If durationFrom is not undefined then format durationTo if not undefined.
 *
 *  durationFrom: 30  durationTo: 65
 *  it will return 30 minutes - 1 hour 5 minutes
 *
 */

export const formatDuration = (course: EclCourseSanity | undefined) => {
  let duration = '';
  if (course?.durationFrom) {
    const { hoursDuration: hoursDurationFrom, minDuration: minDurationFrom } = formatMinutes(course?.durationFrom);

    if (hoursDurationFrom || minDurationFrom) {
      duration = humanizeDuration(hoursDurationFrom, minDurationFrom);
    }
    //if same value, skip durationTo
    if (course?.durationFrom && course?.durationTo && course?.durationTo != course?.durationFrom) {
      const { hoursDuration: hoursDurationTo, minDuration: minDurationTo } = formatMinutes(course?.durationTo);

      //check if we have same unit hours/minutes set for the from and to values
      const skipHumanizeFrom =
        ((hoursDurationFrom?.hours() ?? 0) > 0 &&
          (hoursDurationTo?.hours() ?? 0 > 0) &&
          !minDurationFrom &&
          !minDurationTo) ||
        (!hoursDurationFrom &&
          !hoursDurationTo &&
          (minDurationFrom?.minutes() ?? 0 > 0) &&
          (minDurationTo?.minutes() ?? 0) > 0);

      const formattedDurationTo = humanizeDuration(hoursDurationTo, minDurationTo);
      //if we have same unit, don't humanize the time, to simplify wording. Ex 1-2 hours instead of 1 hours - 2 hours
      duration =
        skipHumanizeFrom && formattedDurationTo != duration
          ? (hoursDurationFrom?.hours() || minDurationFrom?.minutes())!.toString()
          : humanizeDuration(hoursDurationFrom, minDurationFrom);

      //we can have different values having few minutes diff
      //dayjs will round them resulting in the same rounded value
      //skip durationTo for this scenario
      if (formattedDurationTo != duration) {
        duration += ' - ' + formattedDurationTo;
      }
    }
  }
  return duration;
};

/**
 * @param  {number} minutes
 *
 * Converts minutes to localized readable hour and minute formats. i.e. 95 to 1 hour 35 minutes.
 */

export const formatMinutes = (minutes: number) => {
  let minDuration, hoursDuration;
  let mins = minutes % 60;

  let hours = Math.floor(minutes / 60);

  //by default the relative time rounding thresold is set to 15 minutes
  //to prevent situations like 119 minutes be transformed into 1 hour 1 hour, we will increment the hours
  if (60 - mins <= 15) {
    hours++;
    mins = 0;
  }
  if (hours > 0) {
    hoursDuration = dayjs.duration(hours, 'hours');
  }

  if (mins > 0) {
    minDuration = dayjs.duration(mins, 'minutes');
  }
  return { hoursDuration, minDuration };
};

const humanizeDuration = (hours: Duration | undefined, minutes: Duration | undefined): string => {
  let duration = '';
  if (hours) {
    duration += hours.humanize();
  }

  if (minutes) {
    duration += ' ' + minutes.humanize();
  }

  return duration;
};

/**
 * Validates an e-mail address against a valid e-mail address format.
 * @param email - Email address which needs to be validated.
 * @returns Boolean value indicating if the e-mail is a valid e-mail address.
 */
export const validEmail = (email: string): boolean => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return pattern.test(email);
};
