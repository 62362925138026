import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';

import dayjs from 'dayjs';
import { switchLocale } from '../day';

const fallbackRegionLanguage = 'en';
let userRegionLanguage = localStorage.getItem('userRegionLanguage') ?? fallbackRegionLanguage;

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    keySeparator: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (value instanceof Date) {
          userRegionLanguage = localStorage.getItem('userRegionLanguage') ?? fallbackRegionLanguage;
          switchLocale(userRegionLanguage, fallbackRegionLanguage);
          return dayjs(value).format(format);
        }
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });

i18n.on('languageChanged', (lng: string) => {
  localStorage.setItem('userLanguage', lng);
});

export default i18n;
