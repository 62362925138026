import { AnyAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import store, { AppDispatch, RootState } from '../..';
import { AuthApi } from '../../../api';
import { User } from '../../../types';
import { GigyaAccountInfo, GigyaAccountProfile, UserSliceState } from './types';
import { resetState as resetOrganization } from '../organization';
import { resetState as resetCourses } from '../courses';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    locale: undefined,
    country: undefined,
    isAuthenticated: undefined,
    isAuthorized: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    hasEligibleCountry: undefined,
    role: undefined,
    initialized: undefined,
    userId: undefined,
    permissions: [],
    market: undefined,
    dateRegistered: undefined,
    lastLogin: undefined,
    hasSignedInOnBehalf: undefined,
    contentLanguage: undefined,
    switchOrganizationEnabled: undefined,
  } as UserSliceState,
  reducers: {
    resetState: (state, _action: AnyAction) => {
      state.locale = undefined;
      state.isAuthenticated = undefined;
      state.initialized = undefined;
      state.email = undefined;
      state.firstName = undefined;
      state.lastName = undefined;
      state.userId = undefined;
      state.market = undefined;
      state.permissions = [];
      state.dateRegistered = undefined;
      state.lastLogin = undefined;
      state.hasSignedInOnBehalf = undefined;
      state.contentLanguage = undefined;
      state.switchOrganizationEnabled = undefined;
    },
    authenticate: (state, action: PayloadAction<boolean | undefined>) => {
      state.isAuthenticated = action.payload;
    },
    setGigyaAccountInfo: (state, action: PayloadAction<GigyaAccountProfile>) => {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.locale = action.payload.locale;
      state.userId = action.payload.userId;
      state.country = action.payload?.country;
      state.dateRegistered = action.payload.registered;
      state.lastLogin = action.payload.lastLogin;
    },
    setUserInfo: (state, action: PayloadAction<User | undefined>) => {
      const user = action.payload;
      const member = user?.currentOrganization?.members.find((m) => m.user.email === user?.email);
      const role = member?.role?.name;
      state.hasEligibleCountry = user?.hasEligibleCountry;
      state.role = role;
      state.companyRoles = user?.companyRoles;
      state.initialized = true;
      state.permissions = member?.permissions?.map((p) => p.permissionId) ?? [];
      state.contentLanguage = user?.userPreferences?.contentLanguage;
      state.switchOrganizationEnabled = user?.switchOrganizationEnabled;
    },
    setMarket: (state, action: PayloadAction<string | undefined>) => {
      state.market = action.payload;
    },
    setHasSignedInOnBehalf: (state, action: PayloadAction<boolean | undefined>) => {
      state.hasSignedInOnBehalf = action.payload;
    },
    setLocale: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.locale = action.payload;
      } else {
        state.locale = process.env.REACT_APP_DEFAULT_LANGUAGE;
      }
    },
    setContentLanguage: (state, action: PayloadAction<string>) => {
      state.contentLanguage = action.payload;
    },
    setAccountData: () => {},
  },
});

export const {
  setLocale,
  authenticate,
  setGigyaAccountInfo,
  setUserInfo,
  resetState,
  setMarket,
  setHasSignedInOnBehalf,
  setContentLanguage,
} = userSlice.actions;

function onLoginHandler(e: GigyaAccountInfo) {
  const dispatch = store.dispatch;
  dispatch(authenticate(true));
  dispatch(
    setGigyaAccountInfo({
      userId: e.UID,
      firstName: e.profile.firstName,
      email: e.profile.email,
      lastName: e.profile.lastName,
      locale: e.profile.locale,
      country: e.data?.organization?.country,
      registered: e.registered,
      lastLogin: e.lastLogin,
    } as GigyaAccountProfile),
  );
}

function onLogoutHandler(eventObj: any) {
  const dispatch = store.dispatch;
  dispatch(resetState());
  dispatch(resetOrganization());
  dispatch(resetCourses());
}

export const logOut = () => (dispatch: AppDispatch) => {
  authenticate(false);
  // @ts-ignore
  window.gigya.accounts.logout({
    callback: (e: any) => {
      if (e.errorCode === 0) {
        dispatch(authenticate(false));
        resetState();

        if (window.Appcues) window.Appcues.reset();
        AuthApi.token = '';
      } else {
        window.location.reload();
      }
    },
  });
};

export const validate =
  (callback: ((email: string, locale: string) => void) | undefined = undefined) =>
  (dispatch: AppDispatch) => {
    // @ts-ignore
    if (window && window.gigya && window.gigya.accounts) {
      // @ts-ignore
      window.gigya.accounts.addEventHandlers({
        onLogin: onLoginHandler,
        onLogout: onLogoutHandler,
      });

      // @ts-ignore
      window.gigya.accounts.getAccountInfo({
        callback: (e: GigyaAccountInfo) => {
          if (e.errorCode === 0) {
            dispatch(authenticate(true));
            dispatch(
              setGigyaAccountInfo({
                userId: e.UID,
                firstName: e.profile.firstName,
                email: e.profile.email,
                lastName: e.profile.lastName,
                locale: e.profile.locale,
                country: e.data?.organization?.country,
                registered: e.registered,
                lastLogin: e.lastLogin,
              } as GigyaAccountProfile),
            );
            dayjs.locale(e.profile.locale);
          } else {
            dispatch(authenticate(false));
          }
          callback && callback(e?.profile?.email, e?.profile?.locale);
        },
        extraProfileFields: 'locale',
      });
    }
  };

export const selectIsAuthorized = (state: RootState) => state?.user?.initialized;
export const selectIsAuthenticated = (state: RootState) => state?.user?.isAuthenticated;
export const selectUserId = (state: RootState) => state?.user?.userId;
export const selectEmail = (state: RootState) => state?.user?.email;
export const selectFirstName = (state: RootState) => state?.user?.firstName;
export const selectLastName = (state: RootState) => state?.user?.lastName;
export const selectLocale = (state: RootState) => state?.user?.locale;
export const selectCountry = (state: RootState) => state?.user?.country;
export const selectPermissions = (state: RootState) => state?.user?.permissions;
export const selectMarket = (state: RootState) => state?.user?.market;
export const selectDateRegistered = (state: RootState) => state?.user?.dateRegistered;
export const selectLastLogin = (state: RootState) => state?.user?.lastLogin;
export const selectCompanyRoles = (state: RootState) => state?.user?.companyRoles;
export const selectHasSignedInOnBehalf = (state: RootState) => state?.user?.hasSignedInOnBehalf;
export const selectSwitchOrganizationEnabled = (state: RootState) => state?.user?.switchOrganizationEnabled;

export const selectUserContentLanguage = createSelector(
  [(state: RootState) => state.user.contentLanguage, (state: RootState) => state?.organization?.organization],
  (contentLanguage, organization) => {
    return contentLanguage ?? organization?.defaultLanguage;
  },
);

export default userSlice.reducer;
